.pointerTarget {
  display: inline-block;
}

.pointerDot {
  height: var(--spacing-1);
  pointer-events: none;
  position: relative;
  width: var(--spacing-1);

  &::before,
  &::after {
    background: var(--color-pointer-dot);
    border-radius: 50%;
    content: '';
    display: block;
    height: var(--spacing-1);
    position: absolute;
    width: var(--spacing-1);
  }

  &::before {
    animation: pointerDot 1.5s infinite ease-in-out both;
  }
}

@keyframes pointerDot {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(6);
  }
}
