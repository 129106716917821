.wrapper {
  cursor: pointer;
  display: grid;
  grid-template: auto / auto 1fr;
}

.radioInput {
  height: var(--spacing-2);
  margin-right: var(--spacing-1);
  position: relative;
  width: var(--spacing-2);

  & input,
  & div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & input {
    opacity: 0;
  }

  & div {
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transition: border-color 100ms ease-out;

    &::after {
      background: var(--color-primary);
      border-radius: 50%;
      content: '';
      display: block;
      height: var(--spacing-1);
      transform: scale(0);
      transition: transform 100ms ease-out;
      width: var(--spacing-1);
    }
  }

  & input:checked + div {
    border-color: var(--color-primary);

    &::after {
      transform: scale(1);
    }
  }

  & input:focus + div {
    border: 2px solid var(--color-border-strong);
  }

  & input:checked:focus + div {
    border: 2px solid var(--color-primary-highlight);

    &::after {
      background: var(--color-primary-highlight);
    }
  }
}
