.centerHorizontally {
  display: flex;
  justify-content: center;
}

.loader {
  align-items: center;
  animation-delay: -0.32s;
  display: flex;
  position: relative;
  text-indent: -9999em;
}

.one,
.two,
.three {
  animation: loader 1.5s infinite ease-in-out both;
  background: var(--color-border);
  border-radius: 50%;
  height: var(--spacing-1);
  margin: 0 var(--spacing-0-5);
  width: var(--spacing-1);
}

.one {
  animation-delay: -0.5s;
}

.two {
  animation-delay: -0.25s;
}

@keyframes loader {
  0%,
  80%,
  100% {
    opacity: 0;
    transform: scale(0);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }
}
