.modal {
  /* TODO: Remove when `useModal` refactored */
  & header {
    display: none;
  }
}

.content {
  align-items: center;
  display: flex;
  margin: var(--spacing-5) var(--spacing-3) var(--spacing-6);
  min-height: 216px;

  & h2 {
    color: var(--color-text-strong);
    margin-bottom: var(--spacing-3);
  }

  & p {
    color: var(--color-text);
  }
}

.buttonText {
  white-space: nowrap;
}

.footer {
  color: var(--color-text-pale);
  flex: 1;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 128px;
}
