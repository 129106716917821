.listWrapper {
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);

  & .arrow {
    height: 0;
    width: 10px;
    z-index: -1;
  }

  &[data-popper-placement^='bottom'] .arrow {
    border-bottom: 5px solid var(--color-background);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 -1px 0 var(--color-border-pale))
      drop-shadow(0 1px 4px rgba(0, 0, 0, 0.1));
    left: 0;
    margin-top: -5px;
    top: 0;
  }

  &[data-popper-placement^='top'] .arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-background);
    bottom: 0;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 -1px 0 var(--color-border-pale))
      drop-shadow(0 1px 4px rgba(0, 0, 0, 0.1));
    left: 0;
    margin-bottom: -5px;
  }
}

.list {
  background: var(--color-background);
  min-width: 128px;
  padding: var(--spacing-1) 0;
}

.button {
  justify-content: start;
  padding: 0 var(--spacing-2);
  width: 100%;
}
