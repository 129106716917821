.modal {
  max-width: 720px;
  min-width: 520px;
}

.text {
  & > p {
    font-size: var(--font-size-content-large);
    line-height: 1.5;
    margin-bottom: var(--spacing-1-5);
  }
}

.selectFile {
  max-width: 520px;
}

.fileButton {
  align-items: center;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  color: var(--color-text-pale);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-content-large);
  gap: var(--spacing-1);
  margin-top: var(--spacing-4);
  padding: var(--spacing-4);
  transition: background 100ms ease-out, color 100ms ease-out;
  width: 100%;

  &:hover {
    background: var(--color-background-highlight);
  }

  &.correctState {
    background: var(--color-background-success);
    color: var(--color-text);

    & .fileIcon {
      color: var(--color-icon-success);
    }
  }

  &.wrongState {
    background: var(--color-background-error);
    color: var(--color-text);

    & .fileIcon {
      color: var(--color-icon-error);
    }
  }
}

.discardSwitch {
  margin-bottom: var(--spacing-4);
}

.transactions {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-2);
  max-height: 30vh;
  min-width: 640px;
  position: relative;
  width: 100%;

  &::after {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    background: linear-gradient(transparent, var(--color-background));
    bottom: 0;
    content: '';
    display: block;
    height: var(--spacing-5);
    position: absolute;
    width: 100%;
    z-index: 100;
  }
}

.scrollable {
  padding-bottom: var(--spacing-4);
}
