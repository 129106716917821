.nameCell {
  align-items: stretch;
  display: flex;
  flex: 1;
  font-weight: 700;
  margin-left: calc(-1 * var(--spacing-4));
  min-width: 130px;
}

.button {
  flex: 1;
  height: var(--spacing-5);
  justify-content: start;
}

.dragHandle {
  align-items: center;
  color: var(--color-text-inactive);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  cursor: grab;
  display: flex;
  height: 100%;
  justify-content: center;
  width: var(--spacing-4);
}

.active .button {
  background: var(--color-background-highlight);
}

.inactive {
  color: var(--color-text-inactive);
}

.empty {
  margin-top: var(--spacing-4);

  & p {
    color: var(--color-text-pale);
    font-size: var(--font-size-content-large);
    line-height: 1.5;
    margin-bottom: var(--spacing-1);
  }
}

/* TODO: DRY with SecurityChart */
.switchWrapper {
  padding: 0 var(--spacing-1-5);
}
