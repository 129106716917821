/* Based on https://css-tricks.com/scrollbars-on-hover/ */

.outer {
  flex: 1;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  mask-image: linear-gradient(to top, transparent, #000),
    linear-gradient(
      to left,
      transparent var(--scrollbar-size),
      #000 var(--scrollbar-size)
    );
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  mask-position: left bottom;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  mask-size: 100% 99999px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: var(--color-scrollbar) transparent;
  transition: mask-position 250ms ease-out;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--color-scrollbar);
    border: 4px solid transparent;
    border-radius: 8px;
  }

  &:hover {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    mask-position: left top;
  }
}

.inner {
  margin-right: calc(-1 * var(--scrollbar-size));
}

.outsideScrollbar {
  margin-right: calc(-1 * var(--scrollbar-size));

  & .inner {
    margin-right: 0;
  }
}
