@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=block);
/* Global variables */

:root {
  /* Color palette (don't use directly in components/views) */
  --color-blue-100: #f0f7ff;
  --color-blue-300: #71b8ff;
  --color-blue-500: #3a8ee1;
  --color-blue-700: #0064c8;
  --color-gray-100: #f8f8f7;
  --color-gray-200: #eeecec;
  --color-gray-300: #cecaca;
  --color-gray-400: #a9a2a2;
  --color-gray-500: #776e6e;
  --color-gray-600: #5d5656;
  --color-gray-700: #403b3b;
  --color-gray-900: #151414;
  --color-green-100: #f0fdfa;
  --color-green-300: #14d2a5;
  --color-green-500: #00a982;
  --color-green-700: #00795d;
  --color-magenta-500: #cc00c4;
  --color-orange-100: #fef5f1;
  --color-orange-500: #ec6c2d;
  --color-orange-700: #ae4009;
  --color-red-100: #fff2f5;
  --color-red-500: #f42752;
  --color-red-700: #b0092c;
  --color-white: #fff;

  /* Semantic colors */

  /* Backgrounds */
  --color-background-error: var(--color-red-100);
  --color-background-highlight: var(--color-gray-100);
  --color-background-highlight-strong: var(--color-gray-200);
  --color-background-info: var(--color-blue-100);
  --color-background-inverted: var(--color-gray-700);
  --color-background-negative: var(--color-red-100);

  /* based on --color-gray-700 */
  --color-background-overlay: rgba(64, 59, 59, 0.2);
  --color-background-positive: var(--color-green-100);
  --color-background-success: var(--color-green-100);
  --color-background-warning: var(--color-orange-100);
  --color-background: var(--color-white);

  /* Borders */
  --color-border: var(--color-gray-300);
  --color-border-inverted-strong: var(--color-gray-400);
  --color-border-inverted: var(--color-gray-500);
  --color-border-pale: var(--color-gray-200);
  --color-border-strong: var(--color-gray-500);

  /* Chart */
  --color-chart-0: var(--color-magenta-500);
  --color-chart-1: var(--color-orange-500);
  --color-chart-2: var(--color-blue-500);
  --color-chart-cost: var(--color-blue-500);
  --color-chart-main: var(--color-green-500);
  --color-chart-main-area: var(--color-green-100);

  /* based on --color-green-300 */
  --color-chart-main-glow: rgba(20, 210, 165, 0.3);

  /* Misc */
  --color-danger: var(--color-red-500);
  --color-danger-highlight: var(--color-red-700);
  --color-icon: var(--color-gray-500);
  --color-icon-error: var(--color-red-700);
  --color-icon-info: var(--color-blue-700);
  --color-icon-pale: var(--color-gray-400);
  --color-icon-success: var(--color-green-700);
  --color-icon-warning: var(--color-orange-700);
  --color-pointer-dot: var(--color-red-500);
  --color-primary: var(--color-green-500);
  --color-primary-highlight: var(--color-green-700);
  --color-scrollbar: var(--color-gray-200);
  --color-tertiary: var(--color-orange-500);

  /* Text */
  --color-text-danger: var(--color-red-700);
  --color-text-error: var(--color-red-700);
  --color-text-inactive: var(--color-gray-400);
  --color-text-inverted-muted: var(--color-gray-300);
  --color-text-inverted-strong: var(--color-white);
  --color-text-inverted: var(--color-gray-100);
  --color-text-link-inverted: var(--color-blue-300);
  --color-text-link: var(--color-blue-700);
  --color-text-negative-strong: var(--color-red-700);
  --color-text-negative: var(--color-red-500);
  --color-text-pale: var(--color-gray-500);
  --color-text-positive-strong: var(--color-green-700);
  --color-text-positive: var(--color-green-500);
  --color-text-strong: var(--color-gray-900);
  --color-text-warning: var(--color-orange-700);
  --color-text: var(--color-gray-700);

  /* Fonts */
  --font-family: 'Lato', 'Helvetica Neue', helvetica, sans-serif;
  --font-size-content-large: 16px;

  /* 14px results in different line height on non-retina screens for
   * font-weights 400 and 700 */
  --font-size-content-medium: 14.01px;
  --font-size-content-small: 12px;
  --font-size-h1: 32px;
  --font-size-h2: 20px;
  --font-size-h3: 16px;
  --line-height-content-large: 20px;
  --line-height-content-medium: 18px;
  --line-height-h1: 48px;

  /* Spacing units */
  --spacing-0-5: calc(0.5 * var(--spacing-1));
  --spacing-1: 8px;
  --spacing-1-5: calc(1.5 * var(--spacing-1));
  --spacing-2: calc(2 * var(--spacing-1));
  --spacing-3: calc(3 * var(--spacing-1));
  --spacing-4: calc(4 * var(--spacing-1));
  --spacing-5: calc(5 * var(--spacing-1));
  --spacing-6: calc(6 * var(--spacing-1));
  --spacing-7: calc(7 * var(--spacing-1));
  --spacing-8: calc(8 * var(--spacing-1));
  --spacing-10: calc(10 * var(--spacing-1));
  --spacing-15: calc(15 * var(--spacing-1));
  --spacing-16: calc(16 * var(--spacing-1));
  --spacing-20: calc(20 * var(--spacing-1));
  --spacing-24: calc(24 * var(--spacing-1));

  /* Semantic spacing */
  --spacing-content-x: var(--spacing-8);
  --spacing-sidebar: 256px;
  --spacing-topbar: 56px;

  /* Z-indices */
  --z-overlay: 2;
  --z-above: 1;
}

/* CSS reset */

*,
::before,
::after {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  /* Disable font boosting in Android Chrome.
   * https://stackoverflow.com/a/16432702/365238
   */
  max-height: 999999px;
}

html,
body,
button,
input,
dd,
dl,
h1,
h2,
h3,
h4,
hr,
p,
th,
td,
ol,
ul {
  margin: 0;
  padding: 0;
}

h1,
h2 {
  font-weight: 400;
}

body {
  background: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-family);
  font-size: var(--font-size-content-medium);
  line-height: 1;
  overflow: hidden;

  /* Disable font autoresizing in iOS Safari. */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

html,
body,
#root {
  height: 100%;
}

button,
input,
textarea {
  background: none;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

table {
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

/* Utility classes */

.right {
  text-align: right;
}

.negative {
  color: var(--color-text-negative);
}

.positive {
  color: var(--color-text-positive);
}

.bold {
  font-weight: 700;
}

.PointerDot_pointerTarget__M5I76 {
  display: inline-block;
}

.PointerDot_pointerDot__UbAMc {
  height: var(--spacing-1);
  pointer-events: none;
  position: relative;
  width: var(--spacing-1)
}

.PointerDot_pointerDot__UbAMc::before,
  .PointerDot_pointerDot__UbAMc::after {
    background: var(--color-pointer-dot);
    border-radius: 50%;
    content: '';
    display: block;
    height: var(--spacing-1);
    position: absolute;
    width: var(--spacing-1);
  }

.PointerDot_pointerDot__UbAMc::before {
    animation: PointerDot_pointerDot__UbAMc 1.5s infinite ease-in-out both;
  }

@keyframes PointerDot_pointerDot__UbAMc {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(6);
  }
}

.ModalOverlay_modalOverlay__2C7ip {
  align-items: center;
  background: var(--color-background-overlay);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-overlay);
}

.ModalOverlay_modalOverlay__2C7ip ~ * {
  z-index: var(--z-overlay);
}

.ToastWrapper_toastWrapper__3Q-Eq {
  bottom: var(--spacing-4);
  display: flex;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
}

.Icon_icon__2o_0R {
  flex-shrink: 0;
  height: calc(1px * var(--icon-size));
  stroke-width: calc(32px / var(--icon-size));
  vertical-align: text-bottom;
  width: calc(1px * var(--icon-size))
}

.Icon_icon__2o_0R.Icon_bold__ttuOt {
    stroke-width: calc(48px / var(--icon-size));
  }

.Icon_icon__2o_0R {

  /* Autoprefixer takes care of this for Safari */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx) {

.Icon_icon__2o_0R {
    stroke-width: calc(24px / var(--icon-size))
}

    .Icon_icon__2o_0R.Icon_bold__ttuOt {
      stroke-width: calc(32px / var(--icon-size));
    }
  }

.Icon_size16__-wGgn {
  --icon-size: 16;
}

.Icon_size24__1ZOcK {
  --icon-size: 24;
}

.Icon_size32__rTkAD {
  --icon-size: 32;
}

.Link_link__N_GWc {
  display: inline-block;
}

.Button_button__2Ce79 {
  cursor: pointer

  /* TODO: Use :focus-visible or a workaround */
}

.Button_button__2Ce79:focus {
    outline: none;
  }

.Button_button__2Ce79::-moz-focus-inner {
    border: 0;
  }

.Button_active__1NQEE {
  font-weight: 700;
}

.Button_icon__3ypXn {
  margin-right: var(--spacing-1);
}

.Button_invertedLinkVariant__29TMi {
  color: var(--color-text-link-inverted)
}

.Button_invertedLinkVariant__29TMi:hover {
    box-shadow: inset 0 -1px 0 0 var(--color-text-link-inverted);
  }

.Button_linkVariant__23wtx {
  --color: var(--color-text-link);

  color: var(--color);
  font-size: inherit;
  padding: 2px 0
}

.Button_linkVariant__23wtx:hover {
    box-shadow: inset 0 -1px 0 0 var(--color);
  }

.Button_linkVariant__23wtx.Button_danger__lSpB4 {
    --color: var(--color-text-danger);
  }

.Button_linkVariant__23wtx .Button_icon__3ypXn {
    margin-right: var(--spacing-0-5);
  }

.Button_lightButtonVariant__32x-_,
.Button_primaryButtonVariant__pzwwx,
.Button_regularButtonVariant__2ltCm {
  --size: var(--spacing-4);

  align-items: center;
  border-radius: 2px;
  display: flex;
  height: var(--size);
  justify-content: center;
  min-width: var(--size);
  padding: 0 var(--spacing-1-5)
}

.Button_lightButtonVariant__32x-_.Button_iconOnly__22Inv, .Button_primaryButtonVariant__pzwwx.Button_iconOnly__22Inv, .Button_regularButtonVariant__2ltCm.Button_iconOnly__22Inv {
    padding: 0 !important;
  }

.Button_lightButtonVariant__32x-_.Button_largeSize__5OGCU, .Button_primaryButtonVariant__pzwwx.Button_largeSize__5OGCU, .Button_regularButtonVariant__2ltCm.Button_largeSize__5OGCU {
    --size: var(--spacing-5);

    font-size: var(--font-size-content-large);
    font-weight: 700;
    line-height: var(--line-height-content-large);
    padding: 0 var(--spacing-2);
  }

.Button_lightButtonVariant__32x-_.Button_round__3ThZZ, .Button_primaryButtonVariant__pzwwx.Button_round__3ThZZ, .Button_regularButtonVariant__2ltCm.Button_round__3ThZZ {
    border-radius: 50%;
  }

.Button_lightButtonVariant__32x-_:hover,
  .Button_lightButtonVariant__32x-_.Button_active__1NQEE {
    background: var(--color-background-highlight);
    background-clip: padding-box;
  }

.Button_primaryButtonVariant__pzwwx {
  background: var(--color-primary);
  color: var(--color-text-inverted-strong)
}

.Button_primaryButtonVariant__pzwwx.Button_danger__lSpB4 {
    background: var(--color-danger);
  }

.Button_primaryButtonVariant__pzwwx:hover {
    background: var(--color-primary-highlight)
  }

.Button_primaryButtonVariant__pzwwx:hover.Button_danger__lSpB4 {
      background: var(--color-danger-highlight);
    }

.Button_regularButtonVariant__2ltCm {
  box-shadow: inset 0 0 0 1px var(--color-border);
  color: var(--color-text-strong)
}

.Button_regularButtonVariant__2ltCm:hover {
    background: var(--color-background-highlight);
  }

.Button_iconOnly__22Inv {
  justify-content: center
}

.Button_iconOnly__22Inv .Button_icon__3ypXn {
    margin-right: 0;
  }

.Header_header__3mObx {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-6) var(--spacing-content-x) var(--spacing-3);
}

.Header_headerGroup__22SL7 {
  display: flex;
}

.Header_headerItem__3qR-F {
  align-items: center;
  display: flex;
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1)
}

.Header_headerItem__3qR-F:not(:first-child) {
    margin-left: var(--spacing-4);
  }

.Header_headerItem__3qR-F.Header_negativeVariant__2in6e {
    color: var(--color-text-negative);
  }

.Header_headerItem__3qR-F.Header_positiveVariant__2DH3P {
    color: var(--color-text-positive);
  }

.Header_headerItem__3qR-F.Header_negativeVariant__2in6e > svg, .Header_headerItem__3qR-F.Header_positiveVariant__2DH3P > svg {
      margin-right: var(--spacing-1);
    }

.Header_headerItem__3qR-F.Header_quantityVariant__3tqLq {
    font-weight: 300;
    margin-left: var(--spacing-1)
  }

.Header_headerItem__3qR-F.Header_quantityVariant__3tqLq::before {
      content: '×';
      margin-right: var(--spacing-1);
    }

.Header_headerHighlight__2BLWG {
  background: var(--color-background-highlight);
  color: var(--color-text);
  display: grid;
  font-size: var(--font-size-content-medium);
  grid-auto-flow: column;
  grid-gap: var(--spacing-1);
  height: var(--spacing-5);
  line-height: var(--spacing-2);
  margin-left: var(--spacing-2);
  padding: var(--spacing-1-5)
}

.Header_headerHighlight__2BLWG.Header_negativeHighlight__3bKtQ {
    background: var(--color-background-negative);
    color: var(--color-text-negative-strong);
  }

.Header_headerHighlight__2BLWG.Header_positiveHighlight__1fy_B {
    background: var(--color-background-positive);
    color: var(--color-text-positive-strong);
  }

.Header_headerHighlight__2BLWG .Header_icon__2X2E3 {
    margin-left: calc(-1 * var(--spacing-0-5));
    margin-right: calc(-1 * var(--spacing-0-5));
  }

.Header_heading__3BwOQ {
  align-items: center;
  display: flex;
  font-weight: 300;
}

.Header_icon__2X2E3 {
  margin-right: var(--spacing-1)
}

.Header_icon__2X2E3.Header_group__1KWSw {
    margin-left: -0.05em;
    margin-top: 0.05em;
  }

.Header_icon__2X2E3.Header_home__2z9XD {
    margin-left: -0.12em;
  }

.Header_icon__2X2E3.Header_security__2FN0Y {
    margin-left: -0.12em;
  }

.Tabs_tab__1XZ6E {
  color: var(--color-text-pale);
  display: block;
  font-size: var(--font-size-content-large);
  margin-right: var(--spacing-4);
  padding: var(--spacing-2) 0
}

.Tabs_tab__1XZ6E.Tabs_active__FvpRL {
    box-shadow: inset 0 -4px 0 0 var(--color-primary) !important;
    color: var(--color-text-strong);
    font-weight: 700;
  }

.Tabs_tab__1XZ6E:hover {
    box-shadow: inset 0 -4px 0 0 var(--color-border);
    color: var(--color-text);
  }

.Tabs_tabs__33r_k {
  display: flex;
}

.HeaderTabs_container__LDrPo {
  margin-bottom: -1px;
  padding: 0 var(--spacing-content-x);
}

.IllustrationPanel_wrapper__3xoof {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 100%;
}

.IllustrationPanel_centerVertically__115C7 {
  min-height: 80vh;
}

.IllustrationPanel_container__3etCl {
  align-items: center;
  display: flex;
}

.IllustrationPanel_illustration__39Ps8 {
  height: auto;
  width: auto;
}

.IllustrationPanel_largeSize__pEWFj .IllustrationPanel_illustration__39Ps8 {
    margin-right: var(--spacing-6);
    max-height: 320px;
    max-width: 320px;
  }

.IllustrationPanel_smallSize__Aa0_C .IllustrationPanel_illustration__39Ps8 {
    margin-right: var(--spacing-5);
    max-height: 240px;
    max-width: 240px;
  }

.IllustrationPanel_content__2Uask p {
    color: var(--color-text-pale);
    font-size: var(--font-size-content-large);
    line-height: 1.5;
    max-width: 320px
  }

.IllustrationPanel_content__2Uask p:not(:last-child) {
      margin-bottom: var(--spacing-3);
    }

.Banner_banner__10zLS {
  align-items: center;
  display: flex;
  height: var(--spacing-5);
  padding: 0 var(--spacing-content-x);
}

.Banner_error__3Tx84 {
  background: var(--color-background-error)
}

.Banner_error__3Tx84 .Banner_icon__EUlua {
    color: var(--color-icon-error);
  }

.Banner_info__3YsH9 {
  background: var(--color-background-info)
}

.Banner_info__3YsH9 .Banner_icon__EUlua {
    color: var(--color-icon-info);
  }

.Banner_warning__3O7sb {
  background: var(--color-background-warning)
}

.Banner_warning__3O7sb .Banner_icon__EUlua {
    color: var(--color-icon-warning);
  }

.Banner_icon__EUlua {
  margin-right: var(--spacing-1);
}

/* Based on https://css-tricks.com/scrollbars-on-hover/ */

.Scrollable_outer__3brqZ {
  flex: 1;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-mask-image: linear-gradient(to top, transparent, #000),
    linear-gradient(
      to left,
      transparent var(--scrollbar-size),
      #000 var(--scrollbar-size)
    );
          mask-image: linear-gradient(to top, transparent, #000),
    linear-gradient(
      to left,
      transparent var(--scrollbar-size),
      #000 var(--scrollbar-size)
    );
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-mask-position: left bottom;
          mask-position: left bottom;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-mask-size: 100% 99999px;
          mask-size: 100% 99999px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: var(--color-scrollbar) transparent;
  transition: -webkit-mask-position 250ms ease-out;
  transition: mask-position 250ms ease-out;
  transition: mask-position 250ms ease-out, -webkit-mask-position 250ms ease-out
}

.Scrollable_outer__3brqZ::-webkit-scrollbar {
    width: 15px;
  }

.Scrollable_outer__3brqZ::-webkit-scrollbar-track {
    background: transparent;
  }

.Scrollable_outer__3brqZ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--color-scrollbar);
    border: 4px solid transparent;
    border-radius: 8px;
  }

.Scrollable_outer__3brqZ:hover {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    -webkit-mask-position: left top;
            mask-position: left top;
  }

.Scrollable_inner__2Spzk {
  margin-right: calc(-1 * var(--scrollbar-size));
}

.Scrollable_outsideScrollbar__3BmII {
  margin-right: calc(-1 * var(--scrollbar-size))
}

.Scrollable_outsideScrollbar__3BmII .Scrollable_inner__2Spzk {
    margin-right: 0;
  }

.Toast_toast__1QPct {
  background: var(--color-background-inverted);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25) !important;
  color: var(--color-text-inverted);
  padding: var(--spacing-2);
  pointer-events: auto;
}

.ButtonFooter_buttonFooter__P3_ig {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-4);
  position: relative
}
.ButtonFooter_buttonFooter__P3_ig :not(:last-child) {
    margin-right: var(--spacing-1);
  }

.Input_input__24dvw {
  display: block;
  width: 100%;
}

.Input_plainVariant__1Psbi {
  height: var(--spacing-4);
}

.Input_regularVariant__22RE9 {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  font-size: var(--font-size-content-large);
  height: var(--spacing-5);
  line-height: var(--line-height-content-large);
  padding: 0 var(--spacing-1-5)
}

.Input_regularVariant__22RE9:focus,
  .Input_regularVariant__22RE9.Input_isOpen__kwK6t {
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
    outline: none;
  }

.Input_regularVariant__22RE9.Input_right__TEO3i {
    text-align: right;
  }

.Input_regularVariant__22RE9[disabled] {
    box-shadow: none;
    color: var(--color-text-pale);
    padding-left: 0;
    padding-right: 0;
  }

.DateInput_plainVariant__28Cxi {
  width: 76px;
}

/* DayPicker classes */

.DateInput_wrapper__1JE1j {
  background: var(--color-background);
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  font-size: var(--font-size-content-medium);
  padding: var(--spacing-2);
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.DateInput_navButtonPrev__2PLpe,
.DateInput_navButtonNext__2rYJW {
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--spacing-1);
  cursor: pointer;
  height: var(--spacing-5);
  opacity: 0.5;
  position: absolute;
  top: var(--spacing-0-5);
  width: var(--spacing-5)
}

.DateInput_navButtonPrev__2PLpe:hover, .DateInput_navButtonNext__2rYJW:hover {
    opacity: 0.8;
  }

.DateInput_navButtonPrev__2PLpe {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  left: var(--spacing-1);
}

.DateInput_navButtonNext__2rYJW {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
  right: var(--spacing-1);
}

.DateInput_caption__1N2lo {
  font-weight: 700;
  margin-bottom: var(--spacing-2);
  text-align: center;
}

.DateInput_weekdays__lu9oa {
  margin-bottom: var(--spacing-1);
}

.DateInput_weekday__1Mqzq {
  color: var(--color-text-pale);
  font-size: var(--font-size-content-small);
  text-align: center
}

.DateInput_weekday__1Mqzq abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

.DateInput_week__2fhkI,
.DateInput_weekdaysRow__3H6cB {
  display: grid;
  grid-template: auto / repeat(7, auto);
}

.DateInput_day__3dgYF {
  border-radius: 50%;
  cursor: pointer;
  line-height: var(--spacing-4);
  text-align: center;
  vertical-align: middle;
  width: var(--spacing-4)
}

.DateInput_day__3dgYF.DateInput_disabled__fATnn {
    pointer-events: none;
  }

.DateInput_day__3dgYF.DateInput_disabled__fATnn,
  .DateInput_day__3dgYF.DateInput_outside__3NY6J {
    color: var(--color-text-inactive);
    cursor: default;
  }

.DateInput_day__3dgYF:not(.DateInput_disabled__fATnn):not(.DateInput_selected__3KM4t):not(.DateInput_outside__3NY6J):hover {
    background-color: var(--color-background-highlight);
  }

.DateInput_day__3dgYF.DateInput_selected__3KM4t:not(.DateInput_disabled__fATnn):not(.DateInput_outside__3NY6J) {
    background-color: var(--color-primary);
    color: var(--color-text-inverted);
  }

.DateInput_day__3dgYF.DateInput_today__3BYT4 {
    font-weight: 700;
  }

.FormLabel_wrapper__2BKUu {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-1);
  width: 100%
}

.FormLabel_wrapper__2BKUu.FormLabel_right__R4wHQ {
    flex-direction: row-reverse;
  }

.FormLabel_label__A3aJr {
  color: var(--color-text-pale);
  font-size: var(--font-size-content-medium);
  line-height: var(--spacing-2);
}

.FormLabel_error__2oEN7 {
  color: var(--color-text-error);
  font-size: var(--font-size-content-small);
  line-height: var(--spacing-2);
}

.SecurityInput_container__24Fv7 {
  --font-size: var(--font-size-content-medium);
  --padding-horizontal: var(--spacing-1);
  --padding-vertical: 7px;

  font-size: var(--font-size);
  min-width: 0
}

.SecurityInput_container__24Fv7 strong {
    color: var(--color-text-strong);
    font-weight: 400;
  }

.SecurityInput_rounded__1iC2F {
  --color-border: var(--color-border-pale);
  --color-icon: var(--color-icon-pale)
}

.SecurityInput_rounded__1iC2F .SecurityInput_inputWrapper__2FEpH {
    border-radius: var(--spacing-2);
  }

.SecurityInput_largeSize__775_C {
  --font-size: var(--font-size-content-large);
  --padding-horizontal: var(--spacing-1-5);
  --padding-vertical: 10px;
}

.SecurityInput_inputWrapper__2FEpH {
  align-items: center;
  background: var(--color-background);
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  display: flex;
  padding: 0 var(--spacing-1)
}

.SecurityInput_inputWrapper__2FEpH:focus-within {
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
  }

.SecurityInput_inputWrapper__2FEpH input {
    background: transparent;
    flex: 1;
    font-size: inherit;
    line-height: var(--line-height-content-medium);
    min-width: 0;
    padding: var(--padding-vertical) var(--spacing-0-5)
  }

.SecurityInput_inputWrapper__2FEpH input::placeholder {
      color: var(--color-text-pale);
      opacity: 1;
    }

.SecurityInput_inputWrapper__2FEpH input:focus {
      outline: none;
    }

.SecurityInput_inputWrapper__2FEpH .SecurityInput_icon__2b-D6 {
    color: var(--color-icon);
  }

.SecurityInput_items__1Sib_ {
  color: var(--color-text-pale);
  min-width: 480px;
}

.SecurityInput_itemsInner__3TKEJ {
  display: table;
}

.SecurityInput_item__2ledy {
  display: table-row;
}

.SecurityInput_noResults__35Z4w {
  padding: var(--spacing-1) var(--spacing-1-5);
}

.SecurityInput_symbol__3iSuw {
  display: table-cell;
  padding: 0 var(--spacing-1-5);
  width: 1%;
}

.SecurityInput_name__3_vx3 {
  display: table-cell;
  max-width: 0;
  overflow: hidden;
  padding: 0 var(--spacing-1-5);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SelectInput_container__2v-1r {
  position: relative;
  width: 100%
}

.SelectInput_container__2v-1r .SelectInput_button__2dl0W {
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

.SelectInput_icon__DlmNR {
  color: var(--color-text-pale);
}

.SelectInput_items__3whRJ {
  background: var(--color-background);
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  display: none;
  font-size: var(--font-size-content-medium);
  left: 0;
  margin-top: var(--spacing-1);
  max-height: 40vh;
  outline: none;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  scrollbar-width: none;
  z-index: var(--z-above)
}

.SelectInput_items__3whRJ::-webkit-scrollbar {
    width: 0;
  }

.SelectInput_items__3whRJ.SelectInput_isOpen__jbBMs {
    display: block;
  }

.SelectInput_item__TJAxs {
  cursor: default;
  line-height: var(--spacing-4);
  padding: 0 var(--spacing-1-5)
}

.SelectInput_item__TJAxs[aria-selected='true'] {
    background: var(--color-background-highlight);
  }

.Modal_modal__2n0zs {
  background: var(--color-background);
  border-radius: 4px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
  max-height: 90vh;
  max-width: 90vw;
  min-width: 320px;
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-4);
}

.Modal_header__3txAo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: calc(-1 * var(--spacing-1-5));
  padding-bottom: var(--spacing-3)
}

.Modal_header__3txAo h2 {
    color: var(--color-text-strong);
  }

.Switch_button__3mlmS {
  --height: var(--spacing-4);
  --inner-size: var(--spacing-1-5);
  --outer-height: var(--spacing-2);
  --outer-padding: calc(0.5 * (var(--outer-height) - var(--inner-size)));
  --outer-width: var(--spacing-3);

  align-items: center;
  cursor: pointer;
  display: flex;
  height: var(--height)
}

.Switch_button__3mlmS:hover .Switch_outer__-SAWj {
      background: var(--color-border-strong);
    }

.Switch_largeSize__2WVtm {
  --height: var(--spacing-5);
  --inner-size: 20px;
  --outer-height: var(--spacing-3);
  --outer-width: var(--spacing-5);

  font-size: var(--font-size-content-large);
}

.Switch_outer__-SAWj {
  background: var(--color-border);
  border-radius: calc(0.5 * var(--outer-height));
  height: var(--outer-height);
  margin-right: var(--spacing-1);
  padding: var(--outer-padding);
  transition: background 100ms ease-out;
  width: var(--outer-width);
}

.Switch_inner__2Q7YA {
  background: white;
  border-radius: calc(0.5 * var(--inner-size));
  height: var(--inner-size);
  transition: transform 100ms ease-out;
  width: var(--inner-size);
}

.Switch_on__3rcfl .Switch_outer__-SAWj {
    background: var(--color-primary);
  }

.Switch_on__3rcfl:hover .Switch_outer__-SAWj {
      background: var(--color-primary-highlight);
    }

.Switch_on__3rcfl .Switch_inner__2Q7YA {
    transform: translateX(
      calc(var(--outer-width) - var(--inner-size) - 2 * var(--outer-padding))
    );
  }

.TransactionFormModal_modal__1k1Kf {
  width: 512px;
}

.TransactionFormModal_dateType__2eQNQ,
.TransactionFormModal_security__2rSCW,
.TransactionFormModal_values__D-gkx {
  margin-bottom: var(--spacing-2);
}

.TransactionFormModal_dateType__2eQNQ {
  display: grid;
  grid-gap: var(--spacing-2);
  grid-template: auto / 1fr 1fr;
}

.TransactionFormModal_values__D-gkx {
  display: grid;
  grid-gap: var(--spacing-2);
  grid-template: auto / 1fr 1fr 1fr 1fr;
}

.TransactionFormModal_addMore__Y04Sn {
  flex: 1;
}

.Alert_alert__3U2GT {
  align-items: center;
  border-radius: 2px;
  display: flex;
  line-height: 1.4;
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-1-5) var(--spacing-2);
}

.Alert_error__2Qbaa {
  background: var(--color-background-error)
}

.Alert_error__2Qbaa .Alert_icon__1mdLT {
    color: var(--color-icon-error);
  }

.Alert_info__IY38w {
  background: var(--color-background-info)
}

.Alert_info__IY38w .Alert_icon__1mdLT {
    color: var(--color-icon-info);
  }

.Alert_warning__2zKCr {
  background: var(--color-background-warning)
}

.Alert_warning__2zKCr .Alert_icon__1mdLT {
    color: var(--color-icon-warning);
  }

.Alert_icon__1mdLT {
  margin-right: var(--spacing-1-5);
}

.OverflowMenu_listWrapper__1m-hL {
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1)
}

.OverflowMenu_listWrapper__1m-hL .OverflowMenu_arrow__1kc8D {
    height: 0;
    width: 10px;
    z-index: -1;
  }

.OverflowMenu_listWrapper__1m-hL[data-popper-placement^='bottom'] .OverflowMenu_arrow__1kc8D {
    border-bottom: 5px solid var(--color-background);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 -1px 0 var(--color-border-pale))
      drop-shadow(0 1px 4px rgba(0, 0, 0, 0.1));
    left: 0;
    margin-top: -5px;
    top: 0;
  }

.OverflowMenu_listWrapper__1m-hL[data-popper-placement^='top'] .OverflowMenu_arrow__1kc8D {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-background);
    bottom: 0;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 -1px 0 var(--color-border-pale))
      drop-shadow(0 1px 4px rgba(0, 0, 0, 0.1));
    left: 0;
    margin-bottom: -5px;
  }

.OverflowMenu_list__2rD1B {
  background: var(--color-background);
  min-width: 128px;
  padding: var(--spacing-1) 0;
}

.OverflowMenu_button__3-s0F {
  justify-content: start;
  padding: 0 var(--spacing-2);
  width: 100%;
}

.Table_table__3M_gK {
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  font-size: var(--font-size-content-medium);
  width: 100%
}

.Table_table__3M_gK th,
  .Table_table__3M_gK .Table_cell__2oRfG {
    height: var(--spacing-5);
    padding: 0 var(--spacing-1-5);
  }

.Table_table__3M_gK th {
    border-bottom: 1px solid var(--color-border-pale);
    color: var(--color-text-pale);
    white-space: nowrap
  }

.Table_table__3M_gK th:hover {
      color: var(--color-text-strong);
    }

.Table_table__3M_gK tr:not(:last-child) td {
      border-bottom: 1px solid var(--color-border-pale);
    }

.Table_table__3M_gK tr:hover .Table_rowHover__GpGNV, .Table_table__3M_gK tr:focus-within .Table_rowHover__GpGNV {
        visibility: visible;
      }

.Table_header__3Dohy {
  font-weight: 400;
  line-height: var(--spacing-2);
  text-align: left;
  -webkit-user-select: none;
          user-select: none;
}

.Table_sorted__2fdxq {
  font-weight: 700;
}

.Table_cell__2oRfG {
  align-items: center;
  display: flex;
  flex: 1;
}

.Table_right__tK6bZ {
  justify-content: flex-end;
  text-align: right;
}

.Table_compactCell__3IlgD {
  white-space: nowrap;
  width: 1%;
}

.Table_rowHover__GpGNV {
  visibility: hidden;
}

.Table_changeCell__2yXC5 {
  justify-content: space-between;
  margin-left: var(--spacing-2)
}

.Table_changeCell__2yXC5 strong {
    flex: 1;
    margin-left: var(--spacing-1);
    text-align: right;
  }

.Table_valueAllocationCell__1pkoQ {
  flex-direction: column;
  justify-content: center;
  margin-left: var(--spacing-2)
}

.Table_valueAllocationCell__1pkoQ .Table_labelWrapper__31J8S {
    display: flex;
    justify-content: space-between;
    padding: 6px 0 4px;
    width: 100%;
  }

.Table_valueAllocationCell__1pkoQ .Table_allocation__23EtE {
    margin-right: var(--spacing-3);
    text-align: right;
    width: 3.3em;
  }

.Table_valueAllocationCell__1pkoQ .Table_percentageBar__2UKkv {
    background: var(--color-background-highlight);
    height: 2px;
    position: relative;
    width: 100%
  }

.Table_valueAllocationCell__1pkoQ .Table_percentageBar__2UKkv div {
      background: var(--color-tertiary);
      border-radius: 2px;
      height: 100%;
    }

.Table_mutedHighlight__2tfkt {
  background: var(--color-background-highlight);
  color: var(--color-text-pale);
}

.Table_negativeHighlight__47cPf {
  background: var(--color-background-negative);
  color: var(--color-text-negative-strong)
}

.Table_negativeHighlight__47cPf svg {
    color: var(--color-text-negative);
  }

.Table_positiveHighlight__2z6lp {
  background: var(--color-background-positive);
  color: var(--color-text-positive-strong)
}

.Table_positiveHighlight__2z6lp svg {
    color: var(--color-text-positive);
  }

.Table_regularHighlight__OUj2e {
  background: var(--color-background-highlight);
}

.Table_overflowMenuWrapper__1hTwY {
  margin-right: calc(-1 * var(--spacing-8))
}

.Table_overflowMenuWrapper__1hTwY .Table_cell__2oRfG {
    box-shadow: none !important;
    margin-left: calc(-1 * var(--spacing-1-5) + 1px);
  }

.TransactionsTable_button__3Qzmd {
  height: var(--spacing-5);
  justify-content: start;
  width: 100%;
}

.ImportCSVModal_modal__2qjEi {
  max-width: 720px;
  min-width: 520px;
}

.ImportCSVModal_text__2EurD > p {
    font-size: var(--font-size-content-large);
    line-height: 1.5;
    margin-bottom: var(--spacing-1-5);
  }

.ImportCSVModal_selectFile__3Kw3U {
  max-width: 520px;
}

.ImportCSVModal_fileButton__3cyhZ {
  align-items: center;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  color: var(--color-text-pale);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-content-large);
  gap: var(--spacing-1);
  margin-top: var(--spacing-4);
  padding: var(--spacing-4);
  transition: background 100ms ease-out, color 100ms ease-out;
  width: 100%
}

.ImportCSVModal_fileButton__3cyhZ:hover {
    background: var(--color-background-highlight);
  }

.ImportCSVModal_fileButton__3cyhZ.ImportCSVModal_correctState__3cEjL {
    background: var(--color-background-success);
    color: var(--color-text)
  }

.ImportCSVModal_fileButton__3cyhZ.ImportCSVModal_correctState__3cEjL .ImportCSVModal_fileIcon__tf2Gu {
      color: var(--color-icon-success);
    }

.ImportCSVModal_fileButton__3cyhZ.ImportCSVModal_wrongState__3Xykj {
    background: var(--color-background-error);
    color: var(--color-text)
  }

.ImportCSVModal_fileButton__3cyhZ.ImportCSVModal_wrongState__3Xykj .ImportCSVModal_fileIcon__tf2Gu {
      color: var(--color-icon-error);
    }

.ImportCSVModal_discardSwitch__3kYiM {
  margin-bottom: var(--spacing-4);
}

.ImportCSVModal_transactions__3GjUU {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-2);
  max-height: 30vh;
  min-width: 640px;
  position: relative;
  width: 100%
}

.ImportCSVModal_transactions__3GjUU::after {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    background: linear-gradient(transparent, var(--color-background));
    bottom: 0;
    content: '';
    display: block;
    height: var(--spacing-5);
    position: absolute;
    width: 100%;
    z-index: 100;
  }

.ImportCSVModal_scrollable__1pTWC {
  padding-bottom: var(--spacing-4);
}

.FormFieldset_fieldset__13JTB {
  border: none;
  display: grid;
  grid-gap: var(--spacing-1);
  padding: 0
}

.FormFieldset_fieldset__13JTB legend {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0 var(--spacing-2);
    width: 100%;
  }

.FormFieldset_error__3TH1j {
  color: var(--color-text-error);
  font-size: var(--font-size-content-small);
}

.RadioInput_wrapper__27RBu {
  cursor: pointer;
  display: grid;
  grid-template: auto / auto 1fr;
}

.RadioInput_radioInput__3s8ha {
  height: var(--spacing-2);
  margin-right: var(--spacing-1);
  position: relative;
  width: var(--spacing-2)
}

.RadioInput_radioInput__3s8ha input,
  .RadioInput_radioInput__3s8ha div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

.RadioInput_radioInput__3s8ha input {
    opacity: 0;
  }

.RadioInput_radioInput__3s8ha div {
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transition: border-color 100ms ease-out
  }

.RadioInput_radioInput__3s8ha div::after {
      background: var(--color-primary);
      border-radius: 50%;
      content: '';
      display: block;
      height: var(--spacing-1);
      transform: scale(0);
      transition: transform 100ms ease-out;
      width: var(--spacing-1);
    }

.RadioInput_radioInput__3s8ha input:checked + div {
    border-color: var(--color-primary)
  }

.RadioInput_radioInput__3s8ha input:checked + div::after {
      transform: scale(1);
    }

.RadioInput_radioInput__3s8ha input:focus + div {
    border: 2px solid var(--color-border-strong);
  }

.RadioInput_radioInput__3s8ha input:checked:focus + div {
    border: 2px solid var(--color-primary-highlight)
  }

.RadioInput_radioInput__3s8ha input:checked:focus + div::after {
      background: var(--color-primary-highlight);
    }

.Loader_centerHorizontally__3YkyP {
  display: flex;
  justify-content: center;
}

.Loader_loader__TLVE4 {
  align-items: center;
  animation-delay: -0.32s;
  display: flex;
  position: relative;
  text-indent: -9999em;
}

.Loader_one__1MxhJ,
.Loader_two__2rr24,
.Loader_three__27iY- {
  animation: Loader_loader__TLVE4 1.5s infinite ease-in-out both;
  background: var(--color-border);
  border-radius: 50%;
  height: var(--spacing-1);
  margin: 0 var(--spacing-0-5);
  width: var(--spacing-1);
}

.Loader_one__1MxhJ {
  animation-delay: -0.5s;
}

.Loader_two__2rr24 {
  animation-delay: -0.25s;
}

@keyframes Loader_loader__TLVE4 {
  0%,
  80%,
  100% {
    opacity: 0;
    transform: scale(0);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }
}

.BillingModal_modal__3Ebcm {
  max-height: 100vh;
  position: relative;
  transition: max-height 500ms ease-out;
  width: 512px
}

.BillingModal_modal__3Ebcm.BillingModal_loading__3vC-q {
    max-height: 196px;
    overflow: hidden;
  }

.BillingModal_loader__VWMo- {
  align-items: center;
  background: var(--color-background);
  border-radius: 4px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-above)
}

.BillingModal_loader__VWMo- p {
    color: var(--color-text-pale);
    font-size: var(--font-size-content-large);
    margin-top: var(--spacing-2);
  }

.BillingModal_paymentCard__30T7E {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  height: var(--spacing-3);
  margin-right: var(--spacing-0-5);
  width: auto;
}

.BillingModal_list__2SDDV {
  border: 1px solid var(--color-border-pale);
  border-radius: 2px
}

.BillingModal_list__2SDDV > div {
    color: var(--color-text-pale);
    padding: var(--spacing-2)
  }

.BillingModal_list__2SDDV > div:not(:last-child) {
      border-bottom: 1px solid var(--color-border-pale);
    }

.BillingModal_list__2SDDV > div > dd {
      display: flex;
      justify-content: space-between;
      margin-top: var(--spacing-1-5);
    }

.BillingModal_sublist__3kiiY {
  display: flex;
  font-size: var(--font-size-content-large)
}

.BillingModal_sublist__3kiiY > li {
    align-items: center;
    color: var(--color-text-pale);
    display: flex
  }

.BillingModal_sublist__3kiiY > li:first-child {
      color: var(--color-text);
    }

.BillingModal_sublist__3kiiY > li:not(:last-child)::after {
      color: var(--color-text-pale);
      content: '·';
      padding: 0 var(--spacing-1);
    }

.BillingModal_footer__1nDfu {
  align-items: center;
  color: var(--color-text-pale);
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-4) 0 var(--spacing-0-5);
}

.BillingModal_cardElement__nhdYu {
  /* !important needed to override Input's display: block */
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--spacing-2);
}

.BillingModal_plans__3hOKn {
  display: grid;
  grid-gap: var(--spacing-3);
  grid-template: auto / 1fr 1fr;
  margin-bottom: var(--spacing-3)
}

.BillingModal_plans__3hOKn li {
    border: 1px solid var(--color-border);
    border-radius: 2px;
    padding: var(--spacing-2);
    width: 100%;
  }

.BillingModal_plans__3hOKn header {
    display: flex;
    justify-content: space-between;
    margin-top: calc(-1 * var(--spacing-0-5));
  }

.BillingModal_plans__3hOKn h3 {
    color: var(--color-text);
    font-size: var(--font-size-content-large);
    font-weight: 400;
    line-height: var(--spacing-3);
  }

.BillingModal_plans__3hOKn .BillingModal_pill__2dV8Y {
    background: var(--color-background-warning);
    border-radius: var(--spacing-1-5);
    color: var(--color-text-warning);
    line-height: var(--spacing-3);
    padding: 0 var(--spacing-1);
  }

.BillingModal_plans__3hOKn .BillingModal_price__3NRJw {
    color: var(--color-text-pale);
    margin: var(--spacing-1) 0 var(--spacing-3)
  }

.BillingModal_plans__3hOKn .BillingModal_price__3NRJw strong {
      color: var(--color-text-strong);
      font-size: var(--font-size-h2);
    }

.BillingModal_plans__3hOKn p {
    color: var(--color-text-pale);
  }

.BillingModal_promoCode__1uXAr {
  align-items: center;
  display: flex;
  gap: var(--spacing-1);
  justify-content: flex-end;
  margin-bottom: var(--spacing-3)
}

.BillingModal_promoCode__1uXAr .BillingModal_sublist__3kiiY {
    flex: 1;
  }

.BillingModal_poweredBy__2XRj9 {
  color: var(--color-text-inactive);
  display: flex;
  flex: 1
}

.BillingModal_poweredBy__2XRj9 svg {
    margin-top: -0.1em;
  }

.BillingModal_cancelQuestion__13gn6 {
  font-size: var(--font-size-content-large);
  margin-top: var(--spacing-4);
}

.Sidebar_linkWrapper__2WA_1 {
  --indent: 0;

  align-items: center;
  border-bottom: 1px solid var(--color-background-highlight);
  border-radius: 0 4px 4px 0;
  display: flex;
  height: var(--spacing-4);
  position: relative;
  width: 100%
}

.Sidebar_linkWrapper__2WA_1.Sidebar_inactive__z7ez_ {
    color: var(--color-text-inactive);
  }

.Sidebar_linkWrapper__2WA_1.Sidebar_dndActive__3X3as {
    border-bottom-color: transparent;
  }

.Sidebar_linkWrapper__2WA_1:not(.Sidebar_dndActive__3X3as):hover,
  .Sidebar_linkWrapper__2WA_1:not(.Sidebar_dndActive__3X3as).Sidebar_active__1IRSY,
  .Sidebar_linkWrapper__2WA_1.Sidebar_dndDrop__2PGvT {
    background: var(--color-background-highlight-strong);
  }

.Sidebar_linkWrapper__2WA_1:not(.Sidebar_dndActive__3X3as):hover .Sidebar_button__3pIFt,
    .Sidebar_linkWrapper__2WA_1:not(.Sidebar_dndActive__3X3as):hover .Sidebar_dragIcon__2GlJk {
      opacity: 1;
    }

.Sidebar_linkWrapper__2WA_1:not(.Sidebar_dndActive__3X3as):focus-within .Sidebar_button__3pIFt {
      opacity: 1;
    }

.Sidebar_linkWrapper__2WA_1 .Sidebar_button__3pIFt,
  .Sidebar_linkWrapper__2WA_1 .Sidebar_dragIcon__2GlJk {
    height: var(--spacing-4);
    width: var(--spacing-4);
  }

.Sidebar_linkWrapper__2WA_1:not(.Sidebar_root__tWMcL) .Sidebar_button__3pIFt,
    .Sidebar_linkWrapper__2WA_1:not(.Sidebar_root__tWMcL) .Sidebar_dragIcon__2GlJk {
      opacity: 0;
    }

.Sidebar_linkWrapper__2WA_1 .Sidebar_dragIcon__2GlJk {
    align-items: center;
    color: var(--color-border);
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: grab;
    display: flex;
    justify-content: center;
    position: absolute;
  }

.Sidebar_linkWrapper__2WA_1 .Sidebar_link__a_8CN {
    align-items: center;
    flex: 1;
    line-height: var(--spacing-4);
    padding-left: calc(var(--spacing-4) + var(--indent) * var(--spacing-3));
  }

.Sidebar_groups__2fZlF {
  padding: var(--spacing-2) var(--spacing-2) 0 0

  /* Workaround for bottom padding in Firefox
   * (https://stackoverflow.com/a/35413665) */
}

.Sidebar_groups__2fZlF::after {
    content: '';
    display: block;
    height: var(--spacing-2);
  }

.Sidebar_logo__2_P-_ {
  margin-right: var(--spacing-1-5);
}

.Sidebar_logoImage__Yb-bo {
  color: var(--color-green-500);
  display: block;
  width: 32px;
}

.Sidebar_sidebar__2go77 {
  background: var(--color-background-highlight);
  border-right: 1px solid var(--color-border);
  box-shadow: inset -2px 0 2px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Sidebar_topBar__3eBRK {
  align-items: center;
  box-shadow: inset 0 -1px 0 0 var(--color-border-pale);
  display: flex;
  height: var(--spacing-topbar);
  padding: var(--spacing-2);

  /* TODO: Remove when SecurityInput uses Popper */
  z-index: calc(var(--z-above) + 1);
}

.FormTextarea_wrapper__2_kut {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  padding: 1px 1px 1px 0
}

.FormTextarea_wrapper__2_kut:focus-within {
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
  }

.FormTextarea_textarea__2SkVi {
  font-size: var(--font-size-content-large);
  line-height: 1.4;
  min-height: var(--spacing-20);
  padding: var(--spacing-1) var(--spacing-1-5);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  resize: none;
  scrollbar-color: var(--color-scrollbar) transparent;
  width: 100%
}

.FormTextarea_textarea__2SkVi::-webkit-scrollbar {
    width: 15px;
  }

.FormTextarea_textarea__2SkVi::-webkit-scrollbar-track {
    background: transparent;
  }

.FormTextarea_textarea__2SkVi::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--color-scrollbar);
    border: 4px solid transparent;
    border-radius: 8px;
  }

.FormTextarea_textarea__2SkVi:focus {
    outline: none;
  }

.SupportModal_modal__Ooeu0 {
  width: 576px;
}

.SupportModal_footer__lNnIs {
  flex: 1;
}

.TopBar_topBar__3u_57 {
  align-items: center;
  display: grid;
  grid-template: auto / 1fr 2fr 1fr;
  height: var(--spacing-topbar);
  padding: 0 var(--spacing-2);
  width: 100%;
  z-index: var(--z-above)
}

.TopBar_topBar__3u_57 > li {
    display: flex;
  }

.TopBar_breadcrumbs__2_9kJ {
  font-size: var(--font-size-content-large);
  justify-self: center
}

.TopBar_breadcrumbs__2_9kJ ol {
    align-items: center;
    display: flex;
  }

.TopBar_breadcrumbs__2_9kJ li {
    align-items: center;
    color: var(--color-text-pale);
    display: flex
  }

.TopBar_breadcrumbs__2_9kJ li:not(:first-child)::before {
      color: var(--color-border);
      content: '/';
      font-size: 24px;
      font-weight: 300;
      margin: 0 var(--spacing-1);
    }

.TopBar_breadcrumbs__2_9kJ .TopBar_button__1Ahi-:hover {
    color: var(--color-text)
  }

.TopBar_breadcrumbs__2_9kJ .TopBar_button__1Ahi-:hover .TopBar_icon__Jrxvn {
      color: var(--color-text);
    }

.TopBar_breadcrumbs__2_9kJ .TopBar_icon__Jrxvn {
    color: var(--color-icon-pale);
  }

.TopBar_user__e6LGx {
  align-items: center;
  color: var(--color-icon-pale);
  display: flex;
  justify-self: right;
}

.TopBar_userButton__16Gkl {
  margin-left: var(--spacing-0-5);
  margin-right: calc(-1 * var(--spacing-0-5));
}

.TopBar_userDetail__2d7qJ {
  border-bottom: 1px solid var(--color-border-pale);
  color: var(--color-text-pale);
  margin-bottom: var(--spacing-1);
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-2);
}

.TopBar_trialEnds__5gX58 {
  color: var(--color-text-warning);
  flex: 1;
  margin-left: var(--spacing-2);
  text-align: right;
}

.TopBar_avatar__fuVdL {
  background-size: cover;
  border-radius: 50%;
  height: var(--spacing-4);
  width: var(--spacing-4);
}

.TopBar_overflowMenu__3ALCv {
  z-index: var(--z-above);
}

.PercentageBar_percentageBar__Aj6pZ {
  background: var(--color-border-pale);
  height: var(--spacing-0-5)
}
.PercentageBar_percentageBar__Aj6pZ div {
    background: var(--color-tertiary);
    border-radius: 2px;
    height: 100%;
    transition: width 250ms;
  }

.WelcomeModal_modal__35K4j {
  /* TODO: Remove when `useModal` refactored */
}

.WelcomeModal_modal__35K4j header {
    display: none;
  }

.WelcomeModal_content__1Dy62 {
  align-items: center;
  display: flex;
  margin: var(--spacing-5) var(--spacing-3) var(--spacing-6);
  min-height: 216px
}

.WelcomeModal_content__1Dy62 h2 {
    color: var(--color-text-strong);
    margin-bottom: var(--spacing-3);
  }

.WelcomeModal_content__1Dy62 p {
    color: var(--color-text);
  }

.WelcomeModal_buttonText__3VZJD {
  white-space: nowrap;
}

.WelcomeModal_footer__3WYAh {
  color: var(--color-text-pale);
  flex: 1;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 128px;
}

.Layout_appWrapper__gBkdW {
  display: flex;
  height: 100%;
}

.Layout_sidebarWrapper__pgvFI {
  width: var(--spacing-sidebar);
}

.Layout_topBarWrapper__T1yzz {
  box-shadow: inset 0 -1px 0 0 var(--color-border-pale);
}

.Layout_mainWrapper__2cRPh {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.Layout_main__t9xuK {
  padding-bottom: var(--spacing-topbar);
}

.Content_content__mFJ5h {
  box-shadow: inset 0 1px 0 0 var(--color-border-pale);
  padding: var(--spacing-3) var(--spacing-content-x) 0
}
.Content_content__mFJ5h.Content_extraSpacing__3SpIg {
    padding-top: var(--spacing-6);
  }

.Toolbar_toolbar__otajl {
  align-items: center;
  display: inline-flex;
  height: var(--spacing-4);
  justify-content: space-between;
  margin-bottom: var(--spacing-1);
  position: relative
}

.Toolbar_toolbar__otajl::after {
    border-radius: 2px;
    bottom: 0;
    box-shadow: inset 0 0 0 1px var(--color-border-pale);
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

.Toolbar_toolbarGroup__15mJA {
  align-items: center;
  align-self: stretch;
  display: flex
}

.Toolbar_toolbarGroup__15mJA:not(:last-child) {
    border-right: 1px solid var(--color-border-pale);
  }

.Toolbar_toolbarLabel__38QX7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  padding: 0 var(--spacing-1-5)
}

.Toolbar_toolbarLabel__38QX7:focus-within {
    border-radius: 2px;
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
    z-index: var(--z-above);
  }

.Toolbar_toolbarLabel__38QX7 input:focus {
    outline: none;
  }

.Toolbar_toolbarLabel__38QX7 .Toolbar_label__18JRL {
    color: var(--color-text-pale);
    margin-right: var(--spacing-0-5);
  }

.ChartActivityTooltip_activity__2ttmY {
  border-top: 1px solid var(--color-border-inverted);
  font-size: var(--font-size-content-small);
  padding-top: var(--spacing-2)
}
.ChartActivityTooltip_activity__2ttmY .ChartActivityTooltip_type__1NgKK {
    color: var(--color-text-inverted-muted);
    width: 1%;
  }
.ChartActivityTooltip_activity__2ttmY .ChartActivityTooltip_tickerSymbol__2Op_y {
    font-weight: 700;
    padding-right: var(--spacing-1);
  }
.ChartActivityTooltip_activity__2ttmY .ChartActivityTooltip_price__3XYt6 {
    width: 1%
  }
.ChartActivityTooltip_activity__2ttmY .ChartActivityTooltip_price__3XYt6::after {
      color: var(--color-text-inverted-muted);
      content: '×';
      margin-left: 0.3em;
    }
.ChartActivityTooltip_activity__2ttmY .ChartActivityTooltip_quantity__3OVha {
    padding-right: var(--spacing-1);
    width: 1%;
  }
.ChartActivityTooltip_activity__2ttmY .ChartActivityTooltip_fromFactor__JHh0M::after {
      color: var(--color-text-inverted-muted);
      content: '→';
      margin-left: 0.3em;
    }
.ChartActivityTooltip_activity__2ttmY .ChartActivityTooltip_toFactor__2W2ao {
    width: 1%;
  }

.ChartMarkerWrapper_chartMarkerWrapper__1N6b6 {
  margin-left: calc(-1 * var(--spacing-1));
  margin-top: calc(-1 * var(--spacing-1));
  pointer-events: none;
  position: absolute;
}

.ComparisonMarker_securityMarker__1FbG_ {
  background: var(--color);
  border-radius: var(--spacing-1);
  color: var(--color-text-inverted);
  font-size: 10px;
  font-weight: 700;
  height: var(--spacing-2);
  line-height: var(--spacing-2);
  text-align: center;
  width: var(--spacing-2);
}

.CostMarker_costMarker__dlP3p {
  border: 2px solid var(--color-chart-cost);
  border-radius: var(--spacing-1);
  height: var(--spacing-2);
  width: var(--spacing-2);
}

.MainMarker_mainMarker__3l9yl {
  background: var(--color-primary);
  background-clip: padding-box;
  border: var(--spacing-0-5) solid var(--color-chart-main-glow);
  border-radius: var(--spacing-1);
  height: var(--spacing-2);
  width: var(--spacing-2);
}

.Tooltip_tooltip__NMJ07 {
  background: var(--color-background-inverted);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  color: var(--color-text-inverted);
  padding: var(--spacing-2);
  pointer-events: none;
  width: 274px
}
.Tooltip_tooltip__NMJ07 .Tooltip_arrow__2pgPx {
    z-index: -1;
  }
.Tooltip_tooltip__NMJ07[data-popper-placement^='bottom'] .Tooltip_arrow__2pgPx {
    border-bottom: 5px solid var(--color-background-inverted);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 0;
    top: -5px;
    width: 10px;
  }
.Tooltip_tooltip__NMJ07[data-popper-placement^='left'] .Tooltip_arrow__2pgPx {
    border-bottom: 5px solid transparent;
    border-left: 5px solid var(--color-background-inverted);
    border-top: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 10px;
    right: -5px;
    width: 0;
  }
.Tooltip_tooltip__NMJ07[data-popper-placement^='right'] .Tooltip_arrow__2pgPx {
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--color-background-inverted);
    border-top: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 10px;
    left: -5px;
    width: 0;
  }
.Tooltip_tooltip__NMJ07[data-popper-placement^='top'] .Tooltip_arrow__2pgPx {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-background-inverted);
    bottom: -5px;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 0;
    width: 10px;
  }
.Tooltip_tooltip__NMJ07 h3 {
    color: var(--color-text-inverted-muted);
    font-size: 12px;
    font-weight: 400;
    line-height: var(--spacing-2);
    text-align: center;
  }
.Tooltip_tooltip__NMJ07 table {
    line-height: var(--spacing-2);
    margin: var(--spacing-2) 0 0;
    white-space: nowrap;
    width: 100%
  }
.Tooltip_tooltip__NMJ07 table td:not(:first-child) {
      padding-left: 0.3em;
    }

.Chart_container__2ThC6 {
  height: calc(320px + var(--x-axis-height));
  margin-bottom: calc(-1 * var(--x-axis-height));

  /* Allow scaling down with vx's ParentSizeModern */
  min-width: 0;
  position: relative;
}

.Chart_loader__3ALAO {
  align-items: center;
  background: var(--color-background);
  bottom: var(--x-axis-height);
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0.75;
  position: absolute;
  right: 0;
  top: 0;
}

.Chart_areaClosed__25nTv {
  fill: var(--color-chart-main-area);
}

.Chart_line__2U3F1 {
  stroke: var(--color-border);
  stroke-width: 1px;
}

.Chart_linePath__1HM1_ {
  --color: var(--color-chart-main);

  stroke: var(--color);
  stroke-width: 2px;
}

.Chart_thinLinePath__3Dm9q {
  stroke: var(--color-chart-cost);
  stroke-width: 1px;
}

.Chart_clickable__2UZAE {
  cursor: pointer;
}

.Chart_tick__POJ1A line {
    stroke: var(--color-border);
  }

.Chart_tickLabel__2K2E9 {
  fill: var(--color-text-pale);
  font-family: var(--font-family);
  font-size: var(--font-size-content-small);
}

.Chart_grid__2p7xs line {
    stroke: var(--color-border);
    stroke-dasharray: 1 2
  }

.Chart_grid__2p7xs line:first-child,
    .Chart_grid__2p7xs line:last-child {
      display: none;
    }

.ChartToolbar_toolbars__1880y {
  display: flex;
  justify-content: space-between;
}

.ChartToolbar_comparisonToolbar__YJ1cY {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: var(--spacing-1);
  margin-left: var(--spacing-4);
  position: relative
}

.ChartToolbar_comparisonToolbar__YJ1cY > div {
    margin-bottom: 0;
  }

.ChartToolbar_comparisonToolbar__YJ1cY .ChartToolbar_label__3Itum {
    color: var(--color-text-pale);
    margin-right: var(--spacing-1-5);
  }

.ChartToolbar_comparisonToolbar__YJ1cY .ChartToolbar_search__2jxbV {
    max-width: 320px;
    position: absolute;
    top: 0;
    width: 100%;
  }

.ChartToolbar_comparisonToolbar__YJ1cY .ChartToolbar_securityLabel__31NEl {
    align-items: center;
    display: grid;
    grid-gap: var(--spacing-0-5);
    grid-template: auto / var(--spacing-2) auto;
    padding-left: var(--spacing-1);
  }

.ChartTooltip_totals__3TiBG {
  border-spacing: 0 var(--spacing-0-5);
  font-size: var(--font-size-content-medium)
}
.ChartTooltip_totals__3TiBG th {
    font-weight: 400;
    text-align: left
  }
.ChartTooltip_totals__3TiBG th > div {
      align-items: center;
      display: grid;
      grid-gap: var(--spacing-1);
      grid-template: auto / var(--spacing-2) auto;
    }
.ChartTooltip_totals__3TiBG td {
    padding-left: var(--spacing-2) !important;
    text-align: right;
  }
.ChartTooltip_totals__3TiBG .ChartTooltip_comparison__1hApe + .ChartTooltip_costBasis__3QvKM th,
    .ChartTooltip_totals__3TiBG .ChartTooltip_comparison__1hApe + .ChartTooltip_costBasis__3QvKM td {
      padding-top: var(--spacing-1-5);
    }
.ChartTooltip_totals__3TiBG .ChartTooltip_main__1OKGl th,
    .ChartTooltip_totals__3TiBG .ChartTooltip_main__1OKGl td {
      font-weight: 700;
    }

.AllocationChart_container__nqeWs {
  line-height: var(--spacing-2);
  margin-top: var(--spacing-3);
  position: relative
}

.AllocationChart_container__nqeWs li {
    --item-index: 0;

    display: grid;
    grid-gap: var(--spacing-0-5) var(--spacing-2);
    grid-template: auto / 4fr 3fr 2fr;
    margin-bottom: var(--spacing-3);
    position: absolute;
    top: calc(var(--item-index) * var(--spacing-6));
    transition: top 250ms;
    width: 100%;
  }

.AllocationChart_percentageBar__3hLol {
  background: var(--color-background-highlight);
  grid-column: 1 / 4;
  grid-row: 2;
}

.AllocationChart_right__1N_lq {
  color: var(--color-text-pale);
  text-align: right;
}

.ChartWithAllocation_container__3G-E_ {
  display: grid;
  grid-gap: var(--spacing-6);
  grid-template: auto / 1fr 272px;
}

.HoldingsTable_nameCell__1kI3D {
  align-items: stretch;
  display: flex;
  flex: 1;
  font-weight: 700;
  margin-left: calc(-1 * var(--spacing-4));
  min-width: 130px;
}

.HoldingsTable_button__1PpoR {
  flex: 1;
  height: var(--spacing-5);
  justify-content: start;
}

.HoldingsTable_dragHandle__1BgYh {
  align-items: center;
  color: var(--color-text-inactive);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  cursor: grab;
  display: flex;
  height: 100%;
  justify-content: center;
  width: var(--spacing-4);
}

.HoldingsTable_active__30B6Q .HoldingsTable_button__1PpoR {
  background: var(--color-background-highlight);
}

.HoldingsTable_inactive__9XWNC {
  color: var(--color-text-inactive);
}

.HoldingsTable_empty__3AkLW {
  margin-top: var(--spacing-4)
}

.HoldingsTable_empty__3AkLW p {
    color: var(--color-text-pale);
    font-size: var(--font-size-content-large);
    line-height: 1.5;
    margin-bottom: var(--spacing-1);
  }

/* TODO: DRY with SecurityChart */
.HoldingsTable_switchWrapper__Z3qDN {
  padding: 0 var(--spacing-1-5);
}

.Group_buttonText__2fA9b {
  white-space: nowrap;
}

.Group_buttonIcon__3BYYF {
  color: var(--color-text);
}

.AuthLayout_main__194dn {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh
}

.AuthLayout_main__194dn p {
    line-height: 1.4;
  }

.AuthLayout_logo__1bFC8 {
  color: var(--color-primary);
  height: 48px;
  margin-bottom: var(--spacing-3);
  width: 48px;
}

.AuthForm_form__6K0LV {
  display: grid;
  grid-gap: var(--spacing-2);
  margin-top: var(--spacing-2);
  width: 320px;
}

.AuthForm_authButton__1gfuw {
  width: 100%;
}

.AuthForm_divider__1z9Tx {
  border: none;
  box-shadow: 0 1px 0 0 var(--color-border-pale);
  display: flex;
  height: var(--spacing-1);
  justify-content: center;
  margin: var(--spacing-2) 0 var(--spacing-1);
  overflow: visible;
  width: 100%
}

.AuthForm_divider__1z9Tx::after {
    background: var(--color-white);
    color: var(--color-text-pale);
    content: 'or';
    height: var(--spacing-2);
    padding: 0 var(--spacing-1);
  }

.AuthForm_footer__3eh57 {
  flex: 1;
}

.SecurityHeader_market__20Ifj {
  color: var(--color-icon-pale);
}

.SecurityNews_content__9ad2r {
  line-height: 1.2;
  margin-top: var(--spacing-2)
}

.SecurityNews_content__9ad2r li {
    margin-bottom: var(--spacing-2);
  }

.SecurityNews_title__3wous {
  font-size: var(--font-size-content-large);
}

.SecurityNews_source__3BlWC {
  color: var(--color-text-pale);
  margin-top: var(--spacing-0-5)
}

.SecurityNews_source__3BlWC time::before {
    content: '·';
    padding: 0 0.5em;
  }

.ChartNewsTooltip_loader__Xqlaj {
  margin-top: var(--spacing-2);
}

.ChartNewsTooltip_news__1ncJv {
  border-top: 1px solid var(--color-border-inverted);
  font-size: var(--font-size-content-small);
  line-height: 1.2;
  margin-top: var(--spacing-2);
  padding-top: var(--spacing-0-5)
}

.ChartNewsTooltip_news__1ncJv li {
    padding-top: var(--spacing-1-5);
  }

.ChartNewsTooltip_source__38-6L {
  color: var(--color-text-inverted-muted)
}

.ChartNewsTooltip_source__38-6L time::before {
    content: '·';
    padding: 0 0.5em;
  }

.SecurityStats_securityStats__2sfmO {
  margin-left: var(--spacing-6);
  margin-top: var(--spacing-2);
  width: 272px
}
.SecurityStats_securityStats__2sfmO div {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    background-image: linear-gradient(
      to right,
      var(--color-border) 1px,
      transparent 1px 3px
    );
    background-position: 0 53%;
    background-repeat: repeat-x;
    background-size: 3px 1px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
.SecurityStats_securityStats__2sfmO dt {
    background: var(--color-background);
    color: var(--color-text-pale);
    padding-right: var(--spacing-1);
  }
.SecurityStats_securityStats__2sfmO dd {
    background: var(--color-background);
    padding-left: var(--spacing-1);
  }

/* TODO: DRY with ChartWithAllocation */
.SecurityChart_container__2jqvA {
  display: grid;
  grid-template: auto / 1fr auto;
}

/* TODO: DRY with HoldingsTable */
.SecurityChart_switchWrapper__2iVc- {
  padding: 0 var(--spacing-1-5);
}

.SecurityDetails_heading__kJl1f {
  align-items: center;
  display: flex;
  line-height: var(--spacing-3)
}

.SecurityDetails_heading__kJl1f h3 {
    font-size: var(--font-size-h3);
    font-weight: 700;
    margin-right: var(--spacing-2);
  }

.SecurityDetails_list__2UOQh {
  display: flex;
  line-height: var(--spacing-3);
  margin-top: var(--spacing-0-5)
}

.SecurityDetails_list__2UOQh div {
    display: flex
  }

.SecurityDetails_list__2UOQh div:not(:last-child)::after {
      color: var(--color-text-pale);
      content: '·';
      padding: 0 0.5em;
    }

.SecurityDetails_list__2UOQh dt {
    color: var(--color-text-pale);
    padding-right: var(--spacing-0-5);
  }

.SecurityDetails_description__Csc-N {
  color: var(--color-text);
  font-size: var(--font-size-content-large);
  line-height: 1.5;
  margin-top: var(--spacing-2);
  width: 50%;
}

