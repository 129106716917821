.plainVariant {
  width: 76px;
}

/* DayPicker classes */

.wrapper {
  background: var(--color-background);
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  font-size: var(--font-size-content-medium);
  padding: var(--spacing-2);
  position: relative;
  user-select: none;
}

.navButtonPrev,
.navButtonNext {
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--spacing-1);
  cursor: pointer;
  height: var(--spacing-5);
  opacity: 0.5;
  position: absolute;
  top: var(--spacing-0-5);
  width: var(--spacing-5);

  &:hover {
    opacity: 0.8;
  }
}

.navButtonPrev {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  left: var(--spacing-1);
}

.navButtonNext {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
  right: var(--spacing-1);
}

.caption {
  font-weight: 700;
  margin-bottom: var(--spacing-2);
  text-align: center;
}

.weekdays {
  margin-bottom: var(--spacing-1);
}

.weekday {
  color: var(--color-text-pale);
  font-size: var(--font-size-content-small);
  text-align: center;

  & abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }
}

.week,
.weekdaysRow {
  display: grid;
  grid-template: auto / repeat(7, auto);
}

.day {
  border-radius: 50%;
  cursor: pointer;
  line-height: var(--spacing-4);
  text-align: center;
  vertical-align: middle;
  width: var(--spacing-4);

  &.disabled {
    pointer-events: none;
  }

  &.disabled,
  &.outside {
    color: var(--color-text-inactive);
    cursor: default;
  }

  &:not(.disabled):not(.selected):not(.outside):hover {
    background-color: var(--color-background-highlight);
  }

  &.selected:not(.disabled):not(.outside) {
    background-color: var(--color-primary);
    color: var(--color-text-inverted);
  }

  &.today {
    font-weight: 700;
  }
}
