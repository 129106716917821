.content {
  line-height: 1.2;
  margin-top: var(--spacing-2);

  & li {
    margin-bottom: var(--spacing-2);
  }
}

.title {
  font-size: var(--font-size-content-large);
}

.source {
  color: var(--color-text-pale);
  margin-top: var(--spacing-0-5);

  & time::before {
    content: '·';
    padding: 0 0.5em;
  }
}
