.button {
  --height: var(--spacing-4);
  --inner-size: var(--spacing-1-5);
  --outer-height: var(--spacing-2);
  --outer-padding: calc(0.5 * (var(--outer-height) - var(--inner-size)));
  --outer-width: var(--spacing-3);

  align-items: center;
  cursor: pointer;
  display: flex;
  height: var(--height);

  &:hover {
    & .outer {
      background: var(--color-border-strong);
    }
  }
}

.largeSize {
  --height: var(--spacing-5);
  --inner-size: 20px;
  --outer-height: var(--spacing-3);
  --outer-width: var(--spacing-5);

  font-size: var(--font-size-content-large);
}

.outer {
  background: var(--color-border);
  border-radius: calc(0.5 * var(--outer-height));
  height: var(--outer-height);
  margin-right: var(--spacing-1);
  padding: var(--outer-padding);
  transition: background 100ms ease-out;
  width: var(--outer-width);
}

.inner {
  background: white;
  border-radius: calc(0.5 * var(--inner-size));
  height: var(--inner-size);
  transition: transform 100ms ease-out;
  width: var(--inner-size);
}

.on {
  & .outer {
    background: var(--color-primary);
  }

  &:hover {
    & .outer {
      background: var(--color-primary-highlight);
    }
  }

  & .inner {
    transform: translateX(
      calc(var(--outer-width) - var(--inner-size) - 2 * var(--outer-padding))
    );
  }
}
