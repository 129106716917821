.modal {
  width: 512px;
}

.dateType,
.security,
.values {
  margin-bottom: var(--spacing-2);
}

.dateType {
  display: grid;
  grid-gap: var(--spacing-2);
  grid-template: auto / 1fr 1fr;
}

.values {
  display: grid;
  grid-gap: var(--spacing-2);
  grid-template: auto / 1fr 1fr 1fr 1fr;
}

.addMore {
  flex: 1;
}
