@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=block');

/* Global variables */

:root {
  /* Color palette (don't use directly in components/views) */
  --color-blue-100: #f0f7ff;
  --color-blue-300: #71b8ff;
  --color-blue-500: #3a8ee1;
  --color-blue-700: #0064c8;
  --color-gray-100: #f8f8f7;
  --color-gray-200: #eeecec;
  --color-gray-300: #cecaca;
  --color-gray-400: #a9a2a2;
  --color-gray-500: #776e6e;
  --color-gray-600: #5d5656;
  --color-gray-700: #403b3b;
  --color-gray-900: #151414;
  --color-green-100: #f0fdfa;
  --color-green-300: #14d2a5;
  --color-green-500: #00a982;
  --color-green-700: #00795d;
  --color-magenta-500: #cc00c4;
  --color-orange-100: #fef5f1;
  --color-orange-500: #ec6c2d;
  --color-orange-700: #ae4009;
  --color-red-100: #fff2f5;
  --color-red-500: #f42752;
  --color-red-700: #b0092c;
  --color-white: #fff;

  /* Semantic colors */

  /* Backgrounds */
  --color-background-error: var(--color-red-100);
  --color-background-highlight: var(--color-gray-100);
  --color-background-highlight-strong: var(--color-gray-200);
  --color-background-info: var(--color-blue-100);
  --color-background-inverted: var(--color-gray-700);
  --color-background-negative: var(--color-red-100);

  /* based on --color-gray-700 */
  --color-background-overlay: rgba(64, 59, 59, 0.2);
  --color-background-positive: var(--color-green-100);
  --color-background-success: var(--color-green-100);
  --color-background-warning: var(--color-orange-100);
  --color-background: var(--color-white);

  /* Borders */
  --color-border: var(--color-gray-300);
  --color-border-inverted-strong: var(--color-gray-400);
  --color-border-inverted: var(--color-gray-500);
  --color-border-pale: var(--color-gray-200);
  --color-border-strong: var(--color-gray-500);

  /* Chart */
  --color-chart-0: var(--color-magenta-500);
  --color-chart-1: var(--color-orange-500);
  --color-chart-2: var(--color-blue-500);
  --color-chart-cost: var(--color-blue-500);
  --color-chart-main: var(--color-green-500);
  --color-chart-main-area: var(--color-green-100);

  /* based on --color-green-300 */
  --color-chart-main-glow: rgba(20, 210, 165, 0.3);

  /* Misc */
  --color-danger: var(--color-red-500);
  --color-danger-highlight: var(--color-red-700);
  --color-icon: var(--color-gray-500);
  --color-icon-error: var(--color-red-700);
  --color-icon-info: var(--color-blue-700);
  --color-icon-pale: var(--color-gray-400);
  --color-icon-success: var(--color-green-700);
  --color-icon-warning: var(--color-orange-700);
  --color-pointer-dot: var(--color-red-500);
  --color-primary: var(--color-green-500);
  --color-primary-highlight: var(--color-green-700);
  --color-scrollbar: var(--color-gray-200);
  --color-tertiary: var(--color-orange-500);

  /* Text */
  --color-text-danger: var(--color-red-700);
  --color-text-error: var(--color-red-700);
  --color-text-inactive: var(--color-gray-400);
  --color-text-inverted-muted: var(--color-gray-300);
  --color-text-inverted-strong: var(--color-white);
  --color-text-inverted: var(--color-gray-100);
  --color-text-link-inverted: var(--color-blue-300);
  --color-text-link: var(--color-blue-700);
  --color-text-negative-strong: var(--color-red-700);
  --color-text-negative: var(--color-red-500);
  --color-text-pale: var(--color-gray-500);
  --color-text-positive-strong: var(--color-green-700);
  --color-text-positive: var(--color-green-500);
  --color-text-strong: var(--color-gray-900);
  --color-text-warning: var(--color-orange-700);
  --color-text: var(--color-gray-700);

  /* Fonts */
  --font-family: 'Lato', 'Helvetica Neue', helvetica, sans-serif;
  --font-size-content-large: 16px;

  /* 14px results in different line height on non-retina screens for
   * font-weights 400 and 700 */
  --font-size-content-medium: 14.01px;
  --font-size-content-small: 12px;
  --font-size-h1: 32px;
  --font-size-h2: 20px;
  --font-size-h3: 16px;
  --line-height-content-large: 20px;
  --line-height-content-medium: 18px;
  --line-height-h1: 48px;

  /* Spacing units */
  --spacing-0-5: calc(0.5 * var(--spacing-1));
  --spacing-1: 8px;
  --spacing-1-5: calc(1.5 * var(--spacing-1));
  --spacing-2: calc(2 * var(--spacing-1));
  --spacing-3: calc(3 * var(--spacing-1));
  --spacing-4: calc(4 * var(--spacing-1));
  --spacing-5: calc(5 * var(--spacing-1));
  --spacing-6: calc(6 * var(--spacing-1));
  --spacing-7: calc(7 * var(--spacing-1));
  --spacing-8: calc(8 * var(--spacing-1));
  --spacing-10: calc(10 * var(--spacing-1));
  --spacing-15: calc(15 * var(--spacing-1));
  --spacing-16: calc(16 * var(--spacing-1));
  --spacing-20: calc(20 * var(--spacing-1));
  --spacing-24: calc(24 * var(--spacing-1));

  /* Semantic spacing */
  --spacing-content-x: var(--spacing-8);
  --spacing-sidebar: 256px;
  --spacing-topbar: 56px;

  /* Z-indices */
  --z-overlay: 2;
  --z-above: 1;
}

/* CSS reset */

*,
::before,
::after {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  /* Disable font boosting in Android Chrome.
   * https://stackoverflow.com/a/16432702/365238
   */
  max-height: 999999px;
}

html,
body,
button,
input,
dd,
dl,
h1,
h2,
h3,
h4,
hr,
p,
th,
td,
ol,
ul {
  margin: 0;
  padding: 0;
}

h1,
h2 {
  font-weight: 400;
}

body {
  background: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-family);
  font-size: var(--font-size-content-medium);
  line-height: 1;
  overflow: hidden;

  /* Disable font autoresizing in iOS Safari. */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-size-adjust: 100%;
}

html,
body,
#root {
  height: 100%;
}

button,
input,
textarea {
  background: none;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

table {
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

/* Utility classes */

.right {
  text-align: right;
}

.negative {
  color: var(--color-text-negative);
}

.positive {
  color: var(--color-text-positive);
}

.bold {
  font-weight: 700;
}
