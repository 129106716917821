.securityMarker {
  background: var(--color);
  border-radius: var(--spacing-1);
  color: var(--color-text-inverted);
  font-size: 10px;
  font-weight: 700;
  height: var(--spacing-2);
  line-height: var(--spacing-2);
  text-align: center;
  width: var(--spacing-2);
}
