.totals {
  border-spacing: 0 var(--spacing-0-5);
  font-size: var(--font-size-content-medium);

  & th {
    font-weight: 400;
    text-align: left;

    & > div {
      align-items: center;
      display: grid;
      grid-gap: var(--spacing-1);
      grid-template: auto / var(--spacing-2) auto;
    }
  }

  & td {
    padding-left: var(--spacing-2) !important;
    text-align: right;
  }

  & .comparison + .costBasis {
    & th,
    & td {
      padding-top: var(--spacing-1-5);
    }
  }

  & .main {
    & th,
    & td {
      font-weight: 700;
    }
  }
}
