.icon {
  flex-shrink: 0;
  height: calc(1px * var(--icon-size));
  stroke-width: calc(32px / var(--icon-size));
  vertical-align: text-bottom;
  width: calc(1px * var(--icon-size));

  &.bold {
    stroke-width: calc(48px / var(--icon-size));
  }

  /* Autoprefixer takes care of this for Safari */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  @media only screen and (min-resolution: 2dppx) {
    stroke-width: calc(24px / var(--icon-size));

    &.bold {
      stroke-width: calc(32px / var(--icon-size));
    }
  }
}

.size16 {
  --icon-size: 16;
}

.size24 {
  --icon-size: 24;
}

.size32 {
  --icon-size: 32;
}
