.appWrapper {
  display: flex;
  height: 100%;
}

.sidebarWrapper {
  width: var(--spacing-sidebar);
}

.topBarWrapper {
  box-shadow: inset 0 -1px 0 0 var(--color-border-pale);
}

.mainWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.main {
  padding-bottom: var(--spacing-topbar);
}
