.fieldset {
  border: none;
  display: grid;
  grid-gap: var(--spacing-1);
  padding: 0;

  & legend {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0 var(--spacing-2);
    width: 100%;
  }
}

.error {
  color: var(--color-text-error);
  font-size: var(--font-size-content-small);
}
