.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 100%;
}

.centerVertically {
  min-height: 80vh;
}

.container {
  align-items: center;
  display: flex;
}

.illustration {
  height: auto;
  width: auto;
}

.largeSize {
  & .illustration {
    margin-right: var(--spacing-6);
    max-height: 320px;
    max-width: 320px;
  }
}

.smallSize {
  & .illustration {
    margin-right: var(--spacing-5);
    max-height: 240px;
    max-width: 240px;
  }
}

.content {
  & p {
    color: var(--color-text-pale);
    font-size: var(--font-size-content-large);
    line-height: 1.5;
    max-width: 320px;

    &:not(:last-child) {
      margin-bottom: var(--spacing-3);
    }
  }
}
