.container {
  height: calc(320px + var(--x-axis-height));
  margin-bottom: calc(-1 * var(--x-axis-height));

  /* Allow scaling down with vx's ParentSizeModern */
  min-width: 0;
  position: relative;
}

.loader {
  align-items: center;
  background: var(--color-background);
  bottom: var(--x-axis-height);
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0.75;
  position: absolute;
  right: 0;
  top: 0;
}

.areaClosed {
  fill: var(--color-chart-main-area);
}

.line {
  stroke: var(--color-border);
  stroke-width: 1px;
}

.linePath {
  --color: var(--color-chart-main);

  stroke: var(--color);
  stroke-width: 2px;
}

.thinLinePath {
  stroke: var(--color-chart-cost);
  stroke-width: 1px;
}

.clickable {
  cursor: pointer;
}

.tick {
  & line {
    stroke: var(--color-border);
  }
}

.tickLabel {
  fill: var(--color-text-pale);
  font-family: var(--font-family);
  font-size: var(--font-size-content-small);
}

.grid {
  & line {
    stroke: var(--color-border);
    stroke-dasharray: 1 2;

    &:first-child,
    &:last-child {
      display: none;
    }
  }
}
