/* TODO: DRY with ChartWithAllocation */
.container {
  display: grid;
  grid-template: auto / 1fr auto;
}

/* TODO: DRY with HoldingsTable */
.switchWrapper {
  padding: 0 var(--spacing-1-5);
}
