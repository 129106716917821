.toolbars {
  display: flex;
  justify-content: space-between;
}

.comparisonToolbar {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: var(--spacing-1);
  margin-left: var(--spacing-4);
  position: relative;

  & > div {
    margin-bottom: 0;
  }

  & .label {
    color: var(--color-text-pale);
    margin-right: var(--spacing-1-5);
  }

  & .search {
    max-width: 320px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & .securityLabel {
    align-items: center;
    display: grid;
    grid-gap: var(--spacing-0-5);
    grid-template: auto / var(--spacing-2) auto;
    padding-left: var(--spacing-1);
  }
}
