.container {
  --font-size: var(--font-size-content-medium);
  --padding-horizontal: var(--spacing-1);
  --padding-vertical: 7px;

  font-size: var(--font-size);
  min-width: 0;

  & strong {
    color: var(--color-text-strong);
    font-weight: 400;
  }
}

.rounded {
  --color-border: var(--color-border-pale);
  --color-icon: var(--color-icon-pale);

  & .inputWrapper {
    border-radius: var(--spacing-2);
  }
}

.largeSize {
  --font-size: var(--font-size-content-large);
  --padding-horizontal: var(--spacing-1-5);
  --padding-vertical: 10px;
}

.inputWrapper {
  align-items: center;
  background: var(--color-background);
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  display: flex;
  padding: 0 var(--spacing-1);

  &:focus-within {
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
  }

  & input {
    background: transparent;
    flex: 1;
    font-size: inherit;
    line-height: var(--line-height-content-medium);
    min-width: 0;
    padding: var(--padding-vertical) var(--spacing-0-5);

    &::placeholder {
      color: var(--color-text-pale);
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }

  & .icon {
    color: var(--color-icon);
  }
}

.items {
  color: var(--color-text-pale);
  min-width: 480px;
}

.itemsInner {
  display: table;
}

.item {
  display: table-row;
}

.noResults {
  padding: var(--spacing-1) var(--spacing-1-5);
}

.symbol {
  display: table-cell;
  padding: 0 var(--spacing-1-5);
  width: 1%;
}

.name {
  display: table-cell;
  max-width: 0;
  overflow: hidden;
  padding: 0 var(--spacing-1-5);
  text-overflow: ellipsis;
  white-space: nowrap;
}
