.activity {
  border-top: 1px solid var(--color-border-inverted);
  font-size: var(--font-size-content-small);
  padding-top: var(--spacing-2);

  & .type {
    color: var(--color-text-inverted-muted);
    width: 1%;
  }

  & .tickerSymbol {
    font-weight: 700;
    padding-right: var(--spacing-1);
  }

  & .price {
    width: 1%;

    &::after {
      color: var(--color-text-inverted-muted);
      content: '×';
      margin-left: 0.3em;
    }
  }

  & .quantity {
    padding-right: var(--spacing-1);
    width: 1%;
  }

  & .fromFactor {
    &::after {
      color: var(--color-text-inverted-muted);
      content: '→';
      margin-left: 0.3em;
    }
  }

  & .toFactor {
    width: 1%;
  }
}
