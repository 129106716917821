.tab {
  color: var(--color-text-pale);
  display: block;
  font-size: var(--font-size-content-large);
  margin-right: var(--spacing-4);
  padding: var(--spacing-2) 0;

  &.active {
    box-shadow: inset 0 -4px 0 0 var(--color-primary) !important;
    color: var(--color-text-strong);
    font-weight: 700;
  }

  &:hover {
    box-shadow: inset 0 -4px 0 0 var(--color-border);
    color: var(--color-text);
  }
}

.tabs {
  display: flex;
}
