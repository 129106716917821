.topBar {
  align-items: center;
  display: grid;
  grid-template: auto / 1fr 2fr 1fr;
  height: var(--spacing-topbar);
  padding: 0 var(--spacing-2);
  width: 100%;
  z-index: var(--z-above);

  & > li {
    display: flex;
  }
}

.breadcrumbs {
  font-size: var(--font-size-content-large);
  justify-self: center;

  & ol {
    align-items: center;
    display: flex;
  }

  & li {
    align-items: center;
    color: var(--color-text-pale);
    display: flex;

    &:not(:first-child)::before {
      color: var(--color-border);
      content: '/';
      font-size: 24px;
      font-weight: 300;
      margin: 0 var(--spacing-1);
    }
  }

  & .button:hover {
    color: var(--color-text);

    & .icon {
      color: var(--color-text);
    }
  }

  & .icon {
    color: var(--color-icon-pale);
  }
}

.user {
  align-items: center;
  color: var(--color-icon-pale);
  display: flex;
  justify-self: right;
}

.userButton {
  margin-left: var(--spacing-0-5);
  margin-right: calc(-1 * var(--spacing-0-5));
}

.userDetail {
  border-bottom: 1px solid var(--color-border-pale);
  color: var(--color-text-pale);
  margin-bottom: var(--spacing-1);
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-2);
}

.trialEnds {
  color: var(--color-text-warning);
  flex: 1;
  margin-left: var(--spacing-2);
  text-align: right;
}

.avatar {
  background-size: cover;
  border-radius: 50%;
  height: var(--spacing-4);
  width: var(--spacing-4);
}

.overflowMenu {
  z-index: var(--z-above);
}
