.buttonFooter {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-4);
  position: relative;

  & :not(:last-child) {
    margin-right: var(--spacing-1);
  }
}
