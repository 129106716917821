.loader {
  margin-top: var(--spacing-2);
}

.news {
  border-top: 1px solid var(--color-border-inverted);
  font-size: var(--font-size-content-small);
  line-height: 1.2;
  margin-top: var(--spacing-2);
  padding-top: var(--spacing-0-5);

  & li {
    padding-top: var(--spacing-1-5);
  }
}

.source {
  color: var(--color-text-inverted-muted);

  & time::before {
    content: '·';
    padding: 0 0.5em;
  }
}
