.container {
  position: relative;
  width: 100%;

  & .button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
}

.icon {
  color: var(--color-text-pale);
}

.items {
  background: var(--color-background);
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  display: none;
  font-size: var(--font-size-content-medium);
  left: 0;
  margin-top: var(--spacing-1);
  max-height: 40vh;
  outline: none;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  scrollbar-width: none;
  z-index: var(--z-above);

  &::-webkit-scrollbar {
    width: 0;
  }

  &.isOpen {
    display: block;
  }
}

.item {
  cursor: default;
  line-height: var(--spacing-4);
  padding: 0 var(--spacing-1-5);

  &[aria-selected='true'] {
    background: var(--color-background-highlight);
  }
}
