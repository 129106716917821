.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  & p {
    line-height: 1.4;
  }
}

.logo {
  color: var(--color-primary);
  height: 48px;
  margin-bottom: var(--spacing-3);
  width: 48px;
}
