.input {
  display: block;
  width: 100%;
}

.plainVariant {
  height: var(--spacing-4);
}

.regularVariant {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  font-size: var(--font-size-content-large);
  height: var(--spacing-5);
  line-height: var(--line-height-content-large);
  padding: 0 var(--spacing-1-5);

  &:focus,
  &.isOpen {
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
    outline: none;
  }

  &.right {
    text-align: right;
  }

  &[disabled] {
    box-shadow: none;
    color: var(--color-text-pale);
    padding-left: 0;
    padding-right: 0;
  }
}
