.modal {
  max-height: 100vh;
  position: relative;
  transition: max-height 500ms ease-out;
  width: 512px;

  &.loading {
    max-height: 196px;
    overflow: hidden;
  }
}

.loader {
  align-items: center;
  background: var(--color-background);
  border-radius: 4px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-above);

  & p {
    color: var(--color-text-pale);
    font-size: var(--font-size-content-large);
    margin-top: var(--spacing-2);
  }
}

.paymentCard {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  height: var(--spacing-3);
  margin-right: var(--spacing-0-5);
  width: auto;
}

.list {
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;

  & > div {
    color: var(--color-text-pale);
    padding: var(--spacing-2);

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-border-pale);
    }

    & > dd {
      display: flex;
      justify-content: space-between;
      margin-top: var(--spacing-1-5);
    }
  }
}

.sublist {
  display: flex;
  font-size: var(--font-size-content-large);

  & > li {
    align-items: center;
    color: var(--color-text-pale);
    display: flex;

    &:first-child {
      color: var(--color-text);
    }

    &:not(:last-child)::after {
      color: var(--color-text-pale);
      content: '·';
      padding: 0 var(--spacing-1);
    }
  }
}

.footer {
  align-items: center;
  color: var(--color-text-pale);
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-4) 0 var(--spacing-0-5);
}

.cardElement {
  /* !important needed to override Input's display: block */
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--spacing-2);
}

.plans {
  display: grid;
  grid-gap: var(--spacing-3);
  grid-template: auto / 1fr 1fr;
  margin-bottom: var(--spacing-3);

  & li {
    border: 1px solid var(--color-border);
    border-radius: 2px;
    padding: var(--spacing-2);
    width: 100%;
  }

  & header {
    display: flex;
    justify-content: space-between;
    margin-top: calc(-1 * var(--spacing-0-5));
  }

  & h3 {
    color: var(--color-text);
    font-size: var(--font-size-content-large);
    font-weight: 400;
    line-height: var(--spacing-3);
  }

  & .pill {
    background: var(--color-background-warning);
    border-radius: var(--spacing-1-5);
    color: var(--color-text-warning);
    line-height: var(--spacing-3);
    padding: 0 var(--spacing-1);
  }

  & .price {
    color: var(--color-text-pale);
    margin: var(--spacing-1) 0 var(--spacing-3);

    & strong {
      color: var(--color-text-strong);
      font-size: var(--font-size-h2);
    }
  }

  & p {
    color: var(--color-text-pale);
  }
}

.promoCode {
  align-items: center;
  display: flex;
  gap: var(--spacing-1);
  justify-content: flex-end;
  margin-bottom: var(--spacing-3);

  & .sublist {
    flex: 1;
  }
}

.poweredBy {
  color: var(--color-text-inactive);
  display: flex;
  flex: 1;

  & svg {
    margin-top: -0.1em;
  }
}

.cancelQuestion {
  font-size: var(--font-size-content-large);
  margin-top: var(--spacing-4);
}
