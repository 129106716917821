.tooltip {
  background: var(--color-background-inverted);
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  color: var(--color-text-inverted);
  padding: var(--spacing-2);
  pointer-events: none;
  width: 274px;

  & .arrow {
    z-index: -1;
  }

  &[data-popper-placement^='bottom'] .arrow {
    border-bottom: 5px solid var(--color-background-inverted);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 0;
    top: -5px;
    width: 10px;
  }

  &[data-popper-placement^='left'] .arrow {
    border-bottom: 5px solid transparent;
    border-left: 5px solid var(--color-background-inverted);
    border-top: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 10px;
    right: -5px;
    width: 0;
  }

  &[data-popper-placement^='right'] .arrow {
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--color-background-inverted);
    border-top: 5px solid transparent;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 10px;
    left: -5px;
    width: 0;
  }

  &[data-popper-placement^='top'] .arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-background-inverted);
    bottom: -5px;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.25));
    height: 0;
    width: 10px;
  }

  & h3 {
    color: var(--color-text-inverted-muted);
    font-size: 12px;
    font-weight: 400;
    line-height: var(--spacing-2);
    text-align: center;
  }

  & table {
    line-height: var(--spacing-2);
    margin: var(--spacing-2) 0 0;
    white-space: nowrap;
    width: 100%;

    & td:not(:first-child) {
      padding-left: 0.3em;
    }
  }
}
