.percentageBar {
  background: var(--color-border-pale);
  height: var(--spacing-0-5);

  & div {
    background: var(--color-tertiary);
    border-radius: 2px;
    height: 100%;
    transition: width 250ms;
  }
}
