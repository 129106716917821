.wrapper {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--color-border);
  padding: 1px 1px 1px 0;

  &:focus-within {
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
  }
}

.textarea {
  font-size: var(--font-size-content-large);
  line-height: 1.4;
  min-height: var(--spacing-20);
  padding: var(--spacing-1) var(--spacing-1-5);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  resize: none;
  scrollbar-color: var(--color-scrollbar) transparent;
  width: 100%;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--color-scrollbar);
    border: 4px solid transparent;
    border-radius: 8px;
  }

  &:focus {
    outline: none;
  }
}
