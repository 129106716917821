.header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-6) var(--spacing-content-x) var(--spacing-3);
}

.headerGroup {
  display: flex;
}

.headerItem {
  align-items: center;
  display: flex;
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);

  &:not(:first-child) {
    margin-left: var(--spacing-4);
  }

  &.negativeVariant {
    color: var(--color-text-negative);
  }

  &.positiveVariant {
    color: var(--color-text-positive);
  }

  &.negativeVariant,
  &.positiveVariant {
    & > svg {
      margin-right: var(--spacing-1);
    }
  }

  &.quantityVariant {
    font-weight: 300;
    margin-left: var(--spacing-1);

    &::before {
      content: '×';
      margin-right: var(--spacing-1);
    }
  }
}

.headerHighlight {
  background: var(--color-background-highlight);
  color: var(--color-text);
  display: grid;
  font-size: var(--font-size-content-medium);
  grid-auto-flow: column;
  grid-gap: var(--spacing-1);
  height: var(--spacing-5);
  line-height: var(--spacing-2);
  margin-left: var(--spacing-2);
  padding: var(--spacing-1-5);

  &.negativeHighlight {
    background: var(--color-background-negative);
    color: var(--color-text-negative-strong);
  }

  &.positiveHighlight {
    background: var(--color-background-positive);
    color: var(--color-text-positive-strong);
  }

  & .icon {
    margin-left: calc(-1 * var(--spacing-0-5));
    margin-right: calc(-1 * var(--spacing-0-5));
  }
}

.heading {
  align-items: center;
  display: flex;
  font-weight: 300;
}

.icon {
  margin-right: var(--spacing-1);

  &.group {
    margin-left: -0.05em;
    margin-top: 0.05em;
  }

  &.home {
    margin-left: -0.12em;
  }

  &.security {
    margin-left: -0.12em;
  }
}
