.table {
  border: 1px solid var(--color-border-pale);
  border-radius: 2px;
  font-size: var(--font-size-content-medium);
  width: 100%;

  & th,
  & .cell {
    height: var(--spacing-5);
    padding: 0 var(--spacing-1-5);
  }

  & th {
    border-bottom: 1px solid var(--color-border-pale);
    color: var(--color-text-pale);
    white-space: nowrap;

    &:hover {
      color: var(--color-text-strong);
    }
  }

  & tr {
    &:not(:last-child) td {
      border-bottom: 1px solid var(--color-border-pale);
    }

    &:hover,
    &:focus-within {
      & .rowHover {
        visibility: visible;
      }
    }
  }
}

.header {
  font-weight: 400;
  line-height: var(--spacing-2);
  text-align: left;
  user-select: none;
}

.sorted {
  font-weight: 700;
}

.cell {
  align-items: center;
  display: flex;
  flex: 1;
}

.right {
  justify-content: flex-end;
  text-align: right;
}

.compactCell {
  white-space: nowrap;
  width: 1%;
}

.rowHover {
  visibility: hidden;
}

.changeCell {
  justify-content: space-between;
  margin-left: var(--spacing-2);

  & strong {
    flex: 1;
    margin-left: var(--spacing-1);
    text-align: right;
  }
}

.valueAllocationCell {
  flex-direction: column;
  justify-content: center;
  margin-left: var(--spacing-2);

  & .labelWrapper {
    display: flex;
    justify-content: space-between;
    padding: 6px 0 4px;
    width: 100%;
  }

  & .allocation {
    margin-right: var(--spacing-3);
    text-align: right;
    width: 3.3em;
  }

  & .percentageBar {
    background: var(--color-background-highlight);
    height: 2px;
    position: relative;
    width: 100%;

    & div {
      background: var(--color-tertiary);
      border-radius: 2px;
      height: 100%;
    }
  }
}

.mutedHighlight {
  background: var(--color-background-highlight);
  color: var(--color-text-pale);
}

.negativeHighlight {
  background: var(--color-background-negative);
  color: var(--color-text-negative-strong);

  & svg {
    color: var(--color-text-negative);
  }
}

.positiveHighlight {
  background: var(--color-background-positive);
  color: var(--color-text-positive-strong);

  & svg {
    color: var(--color-text-positive);
  }
}

.regularHighlight {
  background: var(--color-background-highlight);
}

.overflowMenuWrapper {
  margin-right: calc(-1 * var(--spacing-8));

  & .cell {
    box-shadow: none !important;
    margin-left: calc(-1 * var(--spacing-1-5) + 1px);
  }
}
