.form {
  display: grid;
  grid-gap: var(--spacing-2);
  margin-top: var(--spacing-2);
  width: 320px;
}

.authButton {
  width: 100%;
}

.divider {
  border: none;
  box-shadow: 0 1px 0 0 var(--color-border-pale);
  display: flex;
  height: var(--spacing-1);
  justify-content: center;
  margin: var(--spacing-2) 0 var(--spacing-1);
  overflow: visible;
  width: 100%;

  &::after {
    background: var(--color-white);
    color: var(--color-text-pale);
    content: 'or';
    height: var(--spacing-2);
    padding: 0 var(--spacing-1);
  }
}

.footer {
  flex: 1;
}
