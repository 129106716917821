.modal {
  background: var(--color-background);
  border-radius: 4px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
  max-height: 90vh;
  max-width: 90vw;
  min-width: 320px;
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-4);
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: calc(-1 * var(--spacing-1-5));
  padding-bottom: var(--spacing-3);

  & h2 {
    color: var(--color-text-strong);
  }
}
