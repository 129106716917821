.container {
  line-height: var(--spacing-2);
  margin-top: var(--spacing-3);
  position: relative;

  & li {
    --item-index: 0;

    display: grid;
    grid-gap: var(--spacing-0-5) var(--spacing-2);
    grid-template: auto / 4fr 3fr 2fr;
    margin-bottom: var(--spacing-3);
    position: absolute;
    top: calc(var(--item-index) * var(--spacing-6));
    transition: top 250ms;
    width: 100%;
  }
}

.percentageBar {
  background: var(--color-background-highlight);
  grid-column: 1 / 4;
  grid-row: 2;
}

.right {
  color: var(--color-text-pale);
  text-align: right;
}
