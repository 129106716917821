.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-1);
  width: 100%;

  &.right {
    flex-direction: row-reverse;
  }
}

.label {
  color: var(--color-text-pale);
  font-size: var(--font-size-content-medium);
  line-height: var(--spacing-2);
}

.error {
  color: var(--color-text-error);
  font-size: var(--font-size-content-small);
  line-height: var(--spacing-2);
}
