.heading {
  align-items: center;
  display: flex;
  line-height: var(--spacing-3);

  & h3 {
    font-size: var(--font-size-h3);
    font-weight: 700;
    margin-right: var(--spacing-2);
  }
}

.list {
  display: flex;
  line-height: var(--spacing-3);
  margin-top: var(--spacing-0-5);

  & div {
    display: flex;

    &:not(:last-child)::after {
      color: var(--color-text-pale);
      content: '·';
      padding: 0 0.5em;
    }
  }

  & dt {
    color: var(--color-text-pale);
    padding-right: var(--spacing-0-5);
  }
}

.description {
  color: var(--color-text);
  font-size: var(--font-size-content-large);
  line-height: 1.5;
  margin-top: var(--spacing-2);
  width: 50%;
}
