.modalOverlay {
  align-items: center;
  background: var(--color-background-overlay);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-overlay);
}

.modalOverlay ~ * {
  z-index: var(--z-overlay);
}
