.toolbar {
  align-items: center;
  display: inline-flex;
  height: var(--spacing-4);
  justify-content: space-between;
  margin-bottom: var(--spacing-1);
  position: relative;

  &::after {
    border-radius: 2px;
    bottom: 0;
    box-shadow: inset 0 0 0 1px var(--color-border-pale);
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.toolbarGroup {
  align-items: center;
  align-self: stretch;
  display: flex;

  &:not(:last-child) {
    border-right: 1px solid var(--color-border-pale);
  }
}

.toolbarLabel {
  align-items: center;
  align-self: stretch;
  display: flex;
  padding: 0 var(--spacing-1-5);

  &:focus-within {
    border-radius: 2px;
    box-shadow: inset 0 0 0 2px var(--color-border-strong);
    z-index: var(--z-above);
  }

  & input:focus {
    outline: none;
  }

  & .label {
    color: var(--color-text-pale);
    margin-right: var(--spacing-0-5);
  }
}
