.banner {
  align-items: center;
  display: flex;
  height: var(--spacing-5);
  padding: 0 var(--spacing-content-x);
}

.error {
  background: var(--color-background-error);

  & .icon {
    color: var(--color-icon-error);
  }
}

.info {
  background: var(--color-background-info);

  & .icon {
    color: var(--color-icon-info);
  }
}

.warning {
  background: var(--color-background-warning);

  & .icon {
    color: var(--color-icon-warning);
  }
}

.icon {
  margin-right: var(--spacing-1);
}
