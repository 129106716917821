.securityStats {
  margin-left: var(--spacing-6);
  margin-top: var(--spacing-2);
  width: 272px;

  & div {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    background-image: linear-gradient(
      to right,
      var(--color-border) 1px,
      transparent 1px 3px
    );
    background-position: 0 53%;
    background-repeat: repeat-x;
    background-size: 3px 1px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }

  & dt {
    background: var(--color-background);
    color: var(--color-text-pale);
    padding-right: var(--spacing-1);
  }

  & dd {
    background: var(--color-background);
    padding-left: var(--spacing-1);
  }
}
