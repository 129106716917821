.button {
  cursor: pointer;

  /* TODO: Use :focus-visible or a workaround */
  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

.active {
  font-weight: 700;
}

.icon {
  margin-right: var(--spacing-1);
}

.invertedLinkVariant {
  color: var(--color-text-link-inverted);

  &:hover {
    box-shadow: inset 0 -1px 0 0 var(--color-text-link-inverted);
  }
}

.linkVariant {
  --color: var(--color-text-link);

  color: var(--color);
  font-size: inherit;
  padding: 2px 0;

  &:hover {
    box-shadow: inset 0 -1px 0 0 var(--color);
  }

  &.danger {
    --color: var(--color-text-danger);
  }

  & .icon {
    margin-right: var(--spacing-0-5);
  }
}

.lightButtonVariant,
.primaryButtonVariant,
.regularButtonVariant {
  --size: var(--spacing-4);

  align-items: center;
  border-radius: 2px;
  display: flex;
  height: var(--size);
  justify-content: center;
  min-width: var(--size);
  padding: 0 var(--spacing-1-5);

  &.iconOnly {
    padding: 0 !important;
  }

  &.largeSize {
    --size: var(--spacing-5);

    font-size: var(--font-size-content-large);
    font-weight: 700;
    line-height: var(--line-height-content-large);
    padding: 0 var(--spacing-2);
  }

  &.round {
    border-radius: 50%;
  }
}

.lightButtonVariant {
  &:hover,
  &.active {
    background: var(--color-background-highlight);
    background-clip: padding-box;
  }
}

.primaryButtonVariant {
  background: var(--color-primary);
  color: var(--color-text-inverted-strong);

  &.danger {
    background: var(--color-danger);
  }

  &:hover {
    background: var(--color-primary-highlight);

    &.danger {
      background: var(--color-danger-highlight);
    }
  }
}

.regularButtonVariant {
  box-shadow: inset 0 0 0 1px var(--color-border);
  color: var(--color-text-strong);

  &:hover {
    background: var(--color-background-highlight);
  }
}

.iconOnly {
  justify-content: center;

  & .icon {
    margin-right: 0;
  }
}
