.alert {
  align-items: center;
  border-radius: 2px;
  display: flex;
  line-height: 1.4;
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-1-5) var(--spacing-2);
}

.error {
  background: var(--color-background-error);

  & .icon {
    color: var(--color-icon-error);
  }
}

.info {
  background: var(--color-background-info);

  & .icon {
    color: var(--color-icon-info);
  }
}

.warning {
  background: var(--color-background-warning);

  & .icon {
    color: var(--color-icon-warning);
  }
}

.icon {
  margin-right: var(--spacing-1-5);
}
