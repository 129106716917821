.linkWrapper {
  --indent: 0;

  align-items: center;
  border-bottom: 1px solid var(--color-background-highlight);
  border-radius: 0 4px 4px 0;
  display: flex;
  height: var(--spacing-4);
  position: relative;
  width: 100%;

  &.inactive {
    color: var(--color-text-inactive);
  }

  &.dndActive {
    border-bottom-color: transparent;
  }

  &:not(.dndActive):hover,
  &:not(.dndActive).active,
  &.dndDrop {
    background: var(--color-background-highlight-strong);
  }

  &:not(.dndActive):hover {
    & .button,
    & .dragIcon {
      opacity: 1;
    }
  }

  &:not(.dndActive):focus-within {
    & .button {
      opacity: 1;
    }
  }

  & .button,
  & .dragIcon {
    height: var(--spacing-4);
    width: var(--spacing-4);
  }

  &:not(.root) {
    & .button,
    & .dragIcon {
      opacity: 0;
    }
  }

  & .dragIcon {
    align-items: center;
    color: var(--color-border);
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: grab;
    display: flex;
    justify-content: center;
    position: absolute;
  }

  & .link {
    align-items: center;
    flex: 1;
    line-height: var(--spacing-4);
    padding-left: calc(var(--spacing-4) + var(--indent) * var(--spacing-3));
  }
}

.groups {
  padding: var(--spacing-2) var(--spacing-2) 0 0;

  /* Workaround for bottom padding in Firefox
   * (https://stackoverflow.com/a/35413665) */
  &::after {
    content: '';
    display: block;
    height: var(--spacing-2);
  }
}

.logo {
  margin-right: var(--spacing-1-5);
}

.logoImage {
  color: var(--color-green-500);
  display: block;
  width: 32px;
}

.sidebar {
  background: var(--color-background-highlight);
  border-right: 1px solid var(--color-border);
  box-shadow: inset -2px 0 2px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topBar {
  align-items: center;
  box-shadow: inset 0 -1px 0 0 var(--color-border-pale);
  display: flex;
  height: var(--spacing-topbar);
  padding: var(--spacing-2);

  /* TODO: Remove when SecurityInput uses Popper */
  z-index: calc(var(--z-above) + 1);
}
